@import "../../styles/style-variables.scss";

.cart-details {
  .arrow-left {
    height: 1.5rem;
    width: 1.5rem;
  }

  .cart-title {
    font-weight: 600;
    font-size: $h2;
  }

  .cart-card {
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    border-radius: 0.25rem;
    background: $white;
    border: 1px solid $gray1;
    margin-left: 4.5rem;
    margin-right: 4.5rem;
    min-height: 5rem;
    margin-top: 1.875rem;
    position: relative;
    margin-top: 2.375rem;

    .cart-card-header {
      display: flex;
      padding: 1rem 2rem 1rem;
      align-items: center;
      border-bottom: 1px solid $gray1;
    }

    .remove-cart {
      height: 1.625rem;
      width: 1.625rem;
    }

    .vendor-name {
      color: $black2;
      font-size: $h5;
      font-weight: 700;
    }

    .vendor-name-title {
      letter-spacing: 0.2em;
      font-size: $caption;
    }

    .cart-card-products {
      padding: 1rem 2rem 1rem 2rem;
      border-bottom: 1px solid $gray1;
      display: flex;
      align-items: center;
    }

    .product-name-wrapper {
      display: inline-block;
    }

    .product-name {
      font-weight: 700;
      font-size: $h5;
      color: $black2;
    }

    .category-name {
      font-weight: 400;
      font-size: $h5;
      color: $gray4;
    }

    .product-price {
      display: inline-block;
      color: $gray4;
      font-size: $h5;
      font-weight: 500;

      .dot {
        background-color: $gray4;
        height: 0.3rem;
        width: 0.3rem;
        border-radius: 5rem;
        display: inline-block;
        margin-bottom: 0.2rem;
      }
    }

    .quantity-display {
      width: 7.18rem;
      height: 2.5rem;
      border: 0.0625rem solid $gray1;
      border-radius: 0.25rem;
      background: $gray5;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .quantity-text {
      font-size: $h5;
      color: $gray4;
      font-weight: 500;
    }

    .quantity-value {
      font-weight: 600;
      color: $black2;
      font-size: $bodyR;
    }

    .edit-icon {
      margin-top: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    .total-text {
      color: $gray4;
      font-size: $h4;
      font-weight: 500;
    }

    .total-text-value {
      color: $black2;
    }

    .left-wrapper {
      display: flex;
      align-items: center;

      .cart-flex-1 {
        flex: 0.1;
      }
      .cart-flex-2 {
        flex: 0.1;
      }
      .cart-flex-3 {
        flex: 0.7;
      }
      .cart-flex-4 {
        flex: 0.2;
      }
    }

    .note-disabled:focus {
      outline: none;
    }

    .note-disabled {
      color: $dark-gray;
      font-size: $h5;
      font-style: italic;
      margin-left: 2.5rem;
      font-weight: 500;
      border: none;
      width: 50%;
    }

    .product-image-wrapper {
      height: 3rem;
      width: 3rem;
      border-radius: 0.125rem;
      overflow: hidden;
    }

    .note-disabled-wrapper {
      padding: 1rem 2rem 1rem 2rem;
    }

    .tick-mark-quantity {
      height: 1.5rem;
      width: 1.5rem;
      margin-top: 0.5rem;
    }

    // .quantity-input {
    //   width: 7.18rem;
    //   height: 2.5rem;
    //   border: 0.0625rem solid $gray1;
    //   border-radius: 0.25rem;
    //   background: $gray5;
    //   padding: 1rem;
    // }

    .border-radius-null {
      border-radius: 0 !important;
    }

    .delivery-type {
      background-color: $white;
      box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
      border-radius: 2.5rem;
      display: flex;
      height: 3rem;
      width: 7.37rem;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 1.5rem;
      bottom: -2rem;
      border: 1px solid $gray1;
    }

    .delivery-text {
      color: $black2;
      font-size: $bodyR;
      font-weight: 600;
    }

    .blue-down-arrow {
      width: 0.9rem;
      height: 0.5rem;
    }
  }

  .cart-details-footer {
    position: fixed;
    bottom: 0;
    background: $white;
    height: 6.25rem;
    box-shadow: 0px -44px 54px rgba(30, 46, 66, 0.04);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    left: 0;

    .grand-total-header {
      color: $gray3;
      font-weight: 700;
      font-size: $caption;
    }

    .total-amount {
      color: $black2;
      font-weight: 700;
      font-size: $h5;
    }
  }

  .type-selection {
    background: $white;
    position: absolute;
    // padding: 1rem 2rem 1rem 2rem;
    bottom: -6rem;
    border-radius: 0 0 1rem 1rem;
    z-index: 9999;
    word-break: keep-all;
  }

  .type-selection-padding {
    padding: 0.8rem 1.78rem;
  }
  .type-selection-border {
    border: 1px solid $gray1;
  }
}

.order-success {
  display: flex;
  margin-left: 30rem;
  margin-right: 30rem;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  .order-success-image {
    width: 9.8rem;
    height: 8.4rem;
  }

  .app-outline-button {
    width: 14rem;
    display: inline-block;
  }

  .app-button-primary {
    width: 14rem;
    display: inline-block;
  }

  .order-placed-title {
    color: $black2;
    font-weight: 700;
    font-size: $h3;
  }
  .order-placed-sub-title {
    color: $gray4;
    font-weight: 500;
    font-size: $h4;
  }
}
