@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/home/assets/fonts/sf-pro-display/sf_pro_display_black_italic.OTF")
    format("OTF");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "SF Pro Display";
//   src: url("../src/home/assets/fonts/sf-pro-display/SFPRODISPLAYULTRALIGHTITALIC.OTF")
//     format("OTF");
//   font-weight: normal;
//   font-style: italic;
// }

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/home/assets/fonts/sf-pro-display/sf_pro_display_bold.OTF")
    format("OTF");
  font-weight: bold;
  font-style: normal;
}

// @font-face {
//   font-family: "SF Pro Display";
//   src: url("../src/home/assets/fonts/sf-pro-display/Mont-BoldItalic.OTF")
//     format("OTF");
//   font-weight: bold;
//   font-style: italic;
// }

// @font-face {
//   font-family: "SF Pro Display";
//   src: url("../src/home/assets/fonts/sf-pro-display/Mont-SemiBold.OTF")
//     format("OTF");
//   font-weight: 600;
//   font-style: normal;
// }

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/home/assets/fonts/sf-pro-display/sf_pro_display_semi_bold_italic.OTF")
    format("OTF");
  font-weight: 600;
  font-style: italic;
}

// @font-face {
//   font-family: "SF Pro Display";
//   src: url("../src/home/assets/fonts/sf-pro-display/Mont-Light.OTF")
//     format("OTF");
//   font-weight: 300;
//   font-style: normal;
// }

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/home/assets/fonts/sf-pro-display/sf_pro_display_light_italic.OTF")
    format("OTF");
  font-weight: 300;
  font-style: italic;
}

// @font-face {
//   font-family: "SF Pro Display";
//   src: url("../src/home/assets/fonts/sf-pro-display/Mont-Thin.OTF")
//     format("OTF");
//   font-weight: 100;
//   font-style: normal;
// }

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/home/assets/fonts/sf-pro-display/sf_pro_display_thin_italic.OTF")
    format("OTF");
  font-weight: 100;
  font-style: italic;
}
