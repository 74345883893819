@import "../../styles/style-variables.scss";

.tenant-workflow {
    overflow: hidden;

    .gray-bg {
        height: 17.0625rem;
        width: 100%;
        position: absolute;
        background: #F2F3F5;
        z-index: -9999;
        left: 0;
        right: 0;
    }

    .padding-top-header {
        margin-top: 4rem;
    }

    .sub-text-wrapper {
        padding-top: 57px;
    }

    .sub-text {
        font-weight: 700;
        font-size: $caption;
        color: $gray4;
        letter-spacing: 0.2em;
    }

    .tenant-workflow-title {
        font-size: $h1;
        font-weight: 700;
        color: $black2;
        letter-spacing: 0
    }

    .mist-card {
        min-height: 322px;
        width: 350px;
        background-color: $white;
        border: 1px solid $gray1;
        box-shadow: 0px 44px 54px rgba(0, 0, 0, 0.04);
        display: inline-block;
        border-radius: 10px;
    }

    .mist-left-card {
        height: fit-content;
        width: 350px;
        background-color: $white;
        border: 1px solid $gray1;
        box-shadow: 0px 44px 54px rgba(0, 0, 0, 0.04);
        display: inline-block;
        border-radius: 10px;
        margin-right: 2rem;
    }

    .mist-right-card {
        display: inline-block;
    }

    .body-container {
        padding-top: 47px;
        padding-bottom: 30px;
    }

    .step-icon-wrapper {
        height: 56px;
        width: 56px;
        border-radius: 30px;
        background-color: $gray5;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .step-icon {}

    .step-index {
        font-size: $bodyR;
        color: $gray4;
        font-weight: 600;
    }

    .step-title {
        font-weight: 500;
        color: $black2;
        font-size: $h5;
    }

    .step-title-active {
        font-weight: 700;
    }

    .active-step {
        border: 1px solid $gray2;
    }

    .step-container {
        padding: 20px;
    }

    .spacing {
        padding: 20px 5px 20px 20px;
    }

    .down-arrow {
        width: 10px;
        height: 7px;
        position: absolute;
        bottom: -25px;
    }

    .progress-fill {
        background-color: $red;
        position: absolute;
        border-radius: 20px;
        height: 5px;
    }

    .progress-bar {
        // width: 242px;
        height: 5px;
        border-radius: 20px;
        background: $gray2;
        overflow: hidden;
        margin-right: 20px
    }

    .green-tick-filled {
        height: 56px;
        width: 56px;
    }

    .green-line {
        height: 55px;
        position: absolute;
        background-color: $green;
        width: 1px;
        bottom: -50px;
    }

    .green-line-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
    }

    .tenant-workflow-final {
        min-height: 100vh;
    }

    .footer-bottom-spacing {
        margin-bottom: 50px;
    }

    .progress-bar-yellow {
        background-color: $yellow;
    }

    .onboarding-container {
        .onboarding-card {
            width: 70rem;
            margin: auto;

            .body {
                padding-top: 2.95rem;
                display: flex;
                margin-bottom: 3rem;
            }

            .header {
                margin-right: 0.3rem;
            }
        }
    }
}