@import "../../../home/styles/style-variables.scss";

.app-sub-header {
  width: 100%;
  background-color: $black2;
  position: sticky;
  top: 4rem;
  z-index: 999;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .route-blocks {
    color: $gray1;
    padding-right: 3rem;
    font-size: $bodyR;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active-route {
    color: $white !important;
    font-weight: 600;
  }

  .sub-header-row {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .active-route-bottom {
    height: 0.25rem;
    position: absolute;
    width: 100%;
    bottom: 0rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .help {
    padding-right: 1rem;
    position: relative;

    .question-mark {
      margin-right: 5px;
      background-color: #435061;
      font-weight: 700;
      color: #9ba2aa;
      border-radius: 50%;
      width: 20px;
      padding-left: 7px;
    }
  }

  .help-menu {
    padding: 1rem;
    padding-left: 2rem;

    .help-title {
      color: $label-black;
      font-weight: 700;
    }

    .help-text {
      color: $primary-black;
      font-size: 12px;
      font-weight: 500;
    }

    .arrow-icon {
      height: 12px;
      margin-left: 5px;
    }

    .green {
      color: #03A685;
    }

    .red {
      color: #FF3F6C;
    }
  }

  .menu-custom-modal {
    background-color: rgba(0, 0, 0, 0.5);
    height: 110%;
    width: 100%;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
  }

  .pop-over-menu {
    .menu-card {
      font-size: $font-size-14px;
      min-height: 8rem;
      max-height: 25rem;
      min-width: 20rem;
      background: $white;
      border-radius: 0.375rem;
      top: 7rem;
      border: 2px solid $gray5;
      position: absolute;
      right: 1rem;
      overflow-y: auto;
    }

    .arrow-up {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem;
      margin-left: 10.5rem;
      margin-top: -0.5rem;
      right: 1.5rem;
      top: 7rem;
    }

    .arrow-up::before {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-bottom-color: white;
      border-width: 0 0.5rem 0.5rem 0.5rem;
    }

    .arrow-up::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

    .arrow-up-wrapper {
      width: 0;
      height: 0;
      border: solid $gray5;
      border-width: 0 0.1rem 0.1rem 0;
      display: inline-block;
      padding: 0.3rem;
      margin: auto;
      margin-top: -8px;

      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .staff-name {
      font-weight: 700;
      font-size: $font-size-14px;
      color: $black2;
    }

    .staff-username {
      font-weight: 500;
      font-size: $bodyM;
      color: $gray4;
    }

    .tick {
      color: $primary;
    }

    .menu-footer-item {
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    .display-none {
      display: none !important;
    }
  }

  .active-route-bottom-bg {
    height: 0.25rem;
    background: $gray3;
    animation-name: activeanimation;
    animation-duration: .5s;
  }


  @keyframes activeanimation {

    0% {
      width: 5%
    }

    100% {
      width: 100%
    }
  }
}