.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem; }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #8898aa;
    background-color: #fff;
    border: 1px solid #dee2e6; }
    .page-link:hover {
      z-index: 2;
      color: #8898aa;
      text-decoration: none;
      background-color: #dee2e6;
      border-color: #dee2e6; }
    .page-link:focus {
      z-index: 2;
      outline: 0;
      box-shadow: none; }
    .page-link:not(:disabled):not(.disabled) {
      cursor: pointer; }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }

  .page-item:last-child .page-link {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem; }

  .page-item.active .page-link {
    z-index: 1;
    color: #21B8C7 !important;
    background-color: #ffffff !important;
    border-color: #21B8C7 !important; 
  }

  .page-item.inactive .page-link {
    z-index: 1;
    color: #212B36 !important;
    background-color: #ffffff !important;
    border-color: #dee2e6 !important; 
  }

  .page-item.disabled .page-link {
    color: #212B36 !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6; }

  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5; }

  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem; }

  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.4375rem;
    border-bottom-right-radius: 0.4375rem; }

  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5; }

  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }


.page-item.active .page-link {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }

  .page-item .page-link,
  .page-item span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 3px;
    border-radius: 20% !important;
    width: 36px;
    height: 36px;
    font-size: 0.875rem; }

  .pagination-lg .page-item .page-link,
  .pagination-lg .page-item span {
    width: 46px;
    height: 46px;
    line-height: 46px; }

  .pagination-sm .page-item .page-link,
  .pagination-sm .page-item span {
    width: 30px;
    height: 30px;
    line-height: 30px; }