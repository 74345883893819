@import "../../../styles/style-variables.scss";

.create-branch {
  border: 1px solid $gray1;
  box-sizing: border-box;
  box-shadow: 0px 44px 54px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 650px !important;
  width: 714px;
  // margin-bottom: 100px;
  background-color: $white;

  .add-branch {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 610px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 50px;
  }

  .create-branch-title {
    font-size: $h3;
    font-weight: 700;
    color: #1e2e42;
  }

  .sub-text-branch {
    font-size: $h5;
    font-weight: 500;
    color: $gray4;
  }

  .vendor-image {
    width: 221px;
    height: 137px;
  }

  .add-branch-step2 {
  }

  .branch-add-title {
    padding: 32px 42px 32px 42px;
    color: $black2;
    font-weight: 700;
    border-bottom: 1px solid $gray5;
  }

  .input-container {
    padding: 25px 42px 25px 42px;
  }

  .selection-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray2;
    font-size: $h5;
    font-weight: 400;
    padding: 16px 24px 16px 24px;
    border-radius: 10px;
    margin-right: 1rem;
    color: $black3;
  }

  .selection-card-active {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $black2;
    font-size: $h5;
    font-weight: 700;
    padding: 16px 24px 16px 24px;
    border-radius: 10px;
    background: $black2;
    color: $white;
    margin-right: 1rem;
  }

  .selection-card-wrapper {
    flex-direction: row;
    display: flex;
  }

  .app-button-primary {
    min-width: 134px;
  }

  .button-wrapper {
    position: absolute;
    bottom: 32px;
    right: 82px;
  }

  .country-code {
    width: 20%;
    border: none;
    font-size: $font-size-14px;
    color: $app-black !important;
    border-right: 1px solid $gray1;
    font-weight: 400 !important;
    padding-left: 0.7rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .country-code:focus {
    outline: none;
  }

  .mobile-number-input {
    width: 80%;
    border: none;
    font-size: $font-size-14px;
    color: $app-black !important;
    padding-left: 10px !important;
  }

  .mobile-number-input:focus {
    outline: none;
  }

  .country-code::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b2aeab;
    opacity: 1; /* Firefox */
  }

  .mobile-number-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b2aeab;
    opacity: 1; /* Firefox */
  }

  .create-branch-wrapper {
    padding-right: 2.625rem;
  }

  .mobile-number-input-wrapper {
    margin: 0 !important;
  }

  .remove-image {
    position: absolute;
    right: 0;
    top: 3rem;
  }

  .image-upload-preview {
    max-height: 6rem;
    overflow: hidden;
  }

  .loading-icon {
    height: 2rem;
    width: 2rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .preview-image {
    width: 19rem;
  }

  .image-upload {
    background: $gray5;
    height: 6rem;
    width: 19rem;
    border-radius: 0.625rem;
    border: 1px solid $gray1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .upload-image {
    border-radius: 5rem;
    width: 4.125rem;
    height: 4.125rem;
    background: $white;
    border: 1px solid $gray1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-image-size {
    height: 1.31rem;
    width: 1.31rem;
  }

  .sub-text {
    font-size: $caption;
    letter-spacing: 0.2em;
    color: $gray8;
    font-weight: 700;
  }

  .browse-text {
    color: $primary;
    font-size: $font-size-12px;
    font-weight: 700;
  }

  .drop-text {
    color: $black2;
    font-size: $font-size-12px;
    font-weight: 500;
  }

  .upload-image-bottom {
    color: $gray3;
    font-weight: 500;
    text-align: center;
    font-size: $bodyR;
    padding-top: 1rem;
  }
}
