@import "../../../../styles/style-variables.scss";

.order-detail {
  font-size: $font-size-14px;

  .order-detail-flex {
    display: flex;
    flex-direction: "row";
  }

  .sub-text {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray4;
  }

  table {
    border-radius: 1rem;
    border: 1px solid $gray1;
  }

  td {
    border: none !important;
    vertical-align: middle !important;
  }

  tr {
    vertical-align: middle;
  }

  .th-max-width {
    width: 12rem;
  }

  .th-product-max-width {
    width: 30rem;
  }

  .tr-complete {
    background-color: $gray5;
    color: $gray3 !important;
    .order-number {
      color: $gray3;
    }
  }

  .img-th {
    width: 3rem !important;
  }

  th {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
    vertical-align: middle !important;
    height: 3rem;
    background-color: $gray5;
  }

  .back-icon {
    position: absolute;
    font-size: $font-size-20px;
    color: $primary;
    left: -2rem;
    top: 0.2rem;
    cursor: pointer;
  }

  .count-badge {
    background-color: black;
    font-weight: 700;
    font-size: $font-size-10px;
    color: white;
    padding: 0.25rem 0.6rem;
    border-radius: 1rem;
  }

  .flex-1 {
    flex: 0.8;
  }
  .flex-2 {
    flex: 0.2;
  }

  .order-detail-card {
    background-color: white;
    padding: 2rem;
    margin-right: 1rem;
  }

  .price-detail {
    background-color: white;
    padding: 1rem;
    height: fit-content;

    .price-summary {
      display: grid;
      align-items: center;
      background-color: #f2f3f5;
      border-radius: 0.5rem;
      height: 3rem;
      font-size: $font-size-16px;
      font-weight: 700;
    }

    .grand-total {
      border-top: 1px solid $dark-gray;
      border-top-style: dashed;
    }

    .total-amount {
      font-weight: 700;
      font-size: $font-size-20px;
    }
  }

  .order-status-wrapper {
    .order-status {
      display: flex;
      align-items: center;
      min-height: 4rem;
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;

      .status {
        font-size: $font-size-14px;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
      }
      .date {
        font-size: $font-size-14px;
        font-weight: 500;
        color: #f2f2f2;
      }
    }

    .status-new {
      background-color: $green;
    }

    .status-pending {
      background-color: $yellow;
    }

    .not-delivered {
      color: $red;
    }

    .status-due {
      background-color: $red;
    }

    .status-partial {
      background-color: $black2;
    }

    .note-new {
      border: 1px solid $green;
      background-color: $green1;
      .note {
        color: $green;
      }
    }

    .note-due {
      border: 1px solid $red;
      background-color: $red1;
      .note {
        color: $red;
      }
    }

    .note-pending {
      border: 1px solid $yellow;
      background-color: $yellow1;
      .note {
        color: $yellow;
      }
    }

    .note-partial {
      border: 1px solid $black2;
      background-color: $gray2;
      .note {
        color: $black2;
      }
    }

    .note-complete {
      border: 1px solid $gray1;
      .note {
        color: $green;
      }
    }

    .status-complete {
      border: 1px solid $gray1;
      background-color: white;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      .status {
        color: $app-black;
      }
      .date {
        color: $primary-black;
      }
    }

    .status-reject {
      border: 1px solid $red;
      background-color: white;
      border-radius: 0.6rem;
      .status {
        color: $app-black;
      }
      .date {
        color: $primary-black;
      }
    }

    .note-sub-text {
      font-weight: 500;
      font-style: italic;
    }

    .order-note {
      border-left: none;
      border-style: dashed;
      border-left-style: none;
      display: flex;
      align-items: center;
      min-height: 4rem;
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    }
  }

  .product-name {
    font-weight: 700;
    font-size: $font-size-16px;
  }

  .deliverable-input-wrapper {
    input {
      width: 5rem;
      min-height: 2rem;
      text-align: right;
      font-weight: 500;
      font-size: $font-size-14px;
      color: $dark-gray;
      text-align: center;
    }
    input:focus {
      outline: none;
    }
  }

  .deliverable-input-border-wrapper {
    border-radius: 0.2rem;
    border: 0.1rem solid $primary;
    padding: 0.54rem 0;

    input {
      width: 3rem;
      min-height: 2rem;
      text-align: right;
      font-weight: 500;
      font-size: $font-size-14px;
      color: $dark-gray;
      text-align: center;
      border: none;
    }
    .count {
      background-color: $gray5;
      color: $app-black;
      font-weight: 600;
      padding: 0.4rem 0.7rem;
    }
    input:focus {
      outline: none;
    }
  }

  .other-detail {
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 2rem;
    background-color: white;
    .other-detail-header {
      font-weight: 700;
      color: $dark-gray;
      font-size: $font-size-14px;
    }

    .flex-content {
      font-weight: 700;
      font-size: $font-size-16px;
    }
  }

  .delivery-date {
    margin-top: 1rem;
    padding: 1rem;
    background-color: white;

    .delivery-date-title {
      font-size: $font-size-14px;
      color: $dark-gray;
    }
    .date {
      font-weight: 700;
      font-size: $font-size-14px;
    }
  }

  .item {
    font-size: 1.12rem;
  }

  .item-status {
    font-weight: 500;
  }

  .delivered-item {
    color: $green;
  }

  .pending-item {
    color: $dark-gray;
  }
  .close-icn {
    position: absolute;
    right: 0.6rem;
  }

  .tick-mark {
    position: absolute;
    color: $green;
    right: 3.5rem;
    top: 1.5rem;
  }
}

.calender-custom-modal {
  min-width: 80%;
}
