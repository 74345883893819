@import "../../../styles/style-variables.scss";


.cart-item-add{
    display: flex;
    flex-direction: row;

    .minus-symbol{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        background: $gray1;
        font-size: $h5;
        color: $black2;
        cursor: pointer;
        font-weight: 700;
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border: 1px solid $gray1;
    }

    .quantity-input{
        width: 60%;

        .app-input{
            border-radius: 0;
            text-align: center;
            padding-right: 1rem;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type=number] {
          -moz-appearance: textfield;
        }
    }

    .plus-symbol{
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $gray1;
        font-size: $h5;
        color: $black2;
        font-weight: 700;
        cursor: pointer;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border: 1px solid $gray1;
    }
}