@import "../../../styles/style-variables.scss";

.sales-chart-1 {
  .card {
    height: 30rem;
    width: 99%;
  }
  .border-left {
    border-left: 1px solid $gray5;
  }

  .chart-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .flex-1 {
    flex: 0.5;
  }
  .flex-2 {
    flex: 0.5;
  }

  .sales-image {
    width: 10rem;
  }

  .sales-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-data-description {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $dark-gray;
  }

  .card-header {
    background-color: $white;
  }

  .cart-title {
    font-weight: 500;
    font-size: $font-size-16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  .chart-container {
    position: relative;
    width: 50%;
    height: 100%;
  }

  .total-order {
    position: absolute;
    width: 100%;
    top: 45%;

    .order-title {
      font-size: $font-size-18px;
      font-weight: 700;
      color: $black2;
    }

    .order-value {
      font-size: $font-size-24px;
      font-weight: 700;
      color: $black2;
    }
  }
  .order-title {
    color: $dark-gray;
    font-weight: 500;
    font-size: $font-size-14px;
  }

  .order-count {
    color: $black2;
    font-size: $font-size-24px;
    font-weight: 600;
  }
}
