//New Module for Inventory INV000001
@import "../../../styles/style-variables.scss";

.inventory-prod-mapping {
  table td {
    vertical-align: middle !important;
  }
}
.product-mapping-modal {

  margin-left: 0;
  margin-right: 0;
  max-width: 100% !important;
  margin-bottom: 0 !important;
  border-radius: none;
  overflow: hidden;

  .modal-body {
    padding: 0;
    background-color: $gray2;
  }

  .modal-content {
    height: 90vh;
    margin-top: 6rem;
  }

  .product-mapping-header {
    height: 5.25rem;
    background: $sidebar-bg;

    .vetrical-center {
      align-items: center;
      height: 100%;
    }
  }

  .product-left-container {
    margin-right: 2.5rem;
  }

  .product-right-container {
    width: 100%;
  }

  .add-product-text {
    color: $white;
    font-size: $h2;
    font-weight: 600;
  }

  .remove-icon {
    height: 1.625rem;
    width: 1.625rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  product-name-list {
    font-weight: 700;
    font-size: $bodyR;
    color: $black2;
  }

  .light-text {
    font-weight: 400;
    color: $gray4;
    font-size: $bodyR;
  }

  semi-bold-text {
    color: $black2;
    font-weight: 500;
    font-size: $bodyR;
  }

.item-input-container {
    // display: flex;
    // flex-direction: row;
  }

.large-font {
  font-size: large !important;
}

  .item-tax-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .action-icon{
    //position: absolute;
    //margin-left: 1.25rem;
    cursor: pointer;

    .icon-active{
      display: none;
    }

    .icon-active:hover{
      display: inline !important;
    }
    
    .icon-inactive{
      display: inline;
    }

    .icon-inactive:hover{
      display: none;
    }
  }

.product-mapping-table {
    background-color: $gray5;
    border-radius: 0.375rem;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);

    tr th {
      color: $black2;
      //color: $red;
      font-weight: 500;
      font-size: 0.9rem;
      border-bottom: none;
      border: none;
      padding-top: 1rem;
      padding-bottom: 1rem;
    } 
  }

  .product-mapping-table-wrapper {
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
  }

.slno-max-width {
    width: 5rem;
  }

  .category-max-width {
    width: 10rem;
  }

  .description-max-width {
    width: 20rem;
  }

  .quantity-max-width {
    width: 10rem;
  }

  .price-max-width {
    width: 10rem;
  }

  .inventory-15-width {
    width: 15rem;
  }

  .added-image {
    height: 4rem;
    width: 4rem;
    border-radius: 5rem;
    // overflow: hidden;
    position: relative;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-icon {
    height: 2rem;
    width: 2rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .preview-container {
    height: 19.75rem;
    width: 22rem;
    overflow: hidden;
  }
  .preview-image {
    width: 100%;
    height: auto;
  }

  .create-inv-product-content {
    display: flex;
    // min-height: 36rem;

    .image-upload {
      background: $gray5;
      height: 19.75rem;
      width: 22rem;
      border-radius: 0.625rem;
      border: 1px solid $gray1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .upload-image {
      border-radius: 5rem;
      width: 4.125rem;
      height: 4.125rem;
      background: $white;
      border: 1px solid $gray1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-image-size {
      height: 1.31rem;
      width: 1.31rem;
    }

    .product-item{
      font-size: 0.875rem !important;
    }

    .sub-text {
      font-weight: 500 !important;
      font-size: $font-size-14px;
      color: $gray4 !important;
    }

    .item-tax-input:focus-visible {
      outline: none !important;
      font-weight: 500;
      color: $gray4;
    }

    item-focus {
      background-color: $blue2 !important;
      border: 1px solid $primary !important;
      height: 100% !important;
      width: 100% !important;
      padding: 0.75rem !important;
    }

    .browse-text {
      color: $primary;
      font-size: $h5;
      font-weight: 700;
    }

    .drop-text {
      color: $black2;
      font-size: $h5;
      font-weight: 500;
    }

    .upload-image-bottom {
      color: $gray3;
      font-weight: 500;
      text-align: center;
      font-size: $bodyR;
      padding-top: 1rem;
    }

    .app-input-text-area {
      min-height: 10.56rem;
    }
  }

  .footer-product-create {
    // position: fixed;
    // bottom: 0;
    height: 6.5rem;
    width: 100%;
    border-top: 1px solid $gray1;
    background: $white;

    .cancel-button {
      width: 6.18rem;
      height: 3rem;
      border: 1px solid $gray2;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black2;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.375rem;
      cursor: pointer;
    }

    .continue-button-disabled {
      width: 7.375rem;
      height: 3rem;
      background: $gray3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.375rem;
      cursor: pointer;
    }

    .continue-button-enabled {
      width: 7.375rem;
      height: 3rem;
      background: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.375rem;
      cursor: pointer;
    }

    .button-container {
      float: right;
      justify-content: center;
      align-items: center;
      // margin-right: 2rem;
      margin-top: 1.5rem;
    }
  }

  .recently-added-title {
    color: $gray4;
    font-size: $font-size-12px;
    font-weight: 700;
    letter-spacing: 0.2em;
  }

  .product-list {
    background: rgba(242, 243, 245, 0.4);
    border: 1px solid rgba(213, 216, 220, 0.5);
    border-radius: 0.625rem;
    // height: 5.5rem;
  }

  .product-container {
    // max-height: 40rem;
    width: 80rem;
    margin: auto;
    height: 100%;
  }

  .preview-image {
    width: 22rem;
  }

  .invqty-display {
    min-width: 2rem;
    height: 2.5rem;
    background: $black2;
    color: $white;
    font-weight: 600;
    font-size: $bodyM;
    display: flex;
    border-radius: 0.375rem;
    justify-content: center;
    align-self: center;
    padding: 0.5rem 0.75rem 0.8rem 0.75rem;
  }

  .recently-added-container {
    margin-bottom: 3rem;
    margin-top: 2rem;
  }

  .product-name {
    color: $black2;
    font-weight: 700;
    font-size: $h3;
  }

  .product-code {
    font-size: $bodyM;
    color: $gray4;
    font-weight: 500;
  }

  .product-code-border {
    border-right: 3px solid $gray4 !important;
  }

  .product-quantity {
    color: $black2;
    font-size: $bodyM;
    font-weight: 500;
  }

  .description-display {
    font-size: $h5;
    font-weight: 500;
    color: $gray4;
  }

  .image-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-display-left {
    width: 20%;
    margin-left: 1rem;
  }

  .image-display-right {
    width: 80%;
  }

  .tr-fixed-5 {
    height: 5rem;
  }

  .produt-list-alignment {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // justify-content: center;
    align-items: center;
  }

  .popup-close-icon {
    height: 2.25rem;
    width: 2.25rem;
  }

  .currency-position {
    position: absolute;
    bottom: 0.75rem;
    left: 2rem;
    font-size: $h5;
    color: $gray4;
  }

  .inventory-input-spacing {
    padding-left: 2rem;
  }

  .product-title-wrapper {
    padding: 0.75rem;
    border-bottom: 1px solid #fff5f5;
  }
  .product-title-wrapper:last-child {
    border-bottom: none;
  }

  .product-drop-down-popover {
    font-size: $font-size-14px;
    position: static;
    background-color: $white;
    border: 1px solid $gray4;
    border-top: none !important;
    width: 10.65rem;
    z-index: 9999;
    border-radius: 0.5rem;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .product-name {
      font-weight: 600;
      color: $gray4;
    }

    .product-list {
      max-height: 12.5rem;
      overflow-y: auto;
    }

    .product-name:hover {
      color: $app-black;
    }

    .product-title-wrapper {
      padding: 0.75rem;
      border-bottom: 1px solid #fff5f5;
    }
    .product-title-wrapper:last-child {
      border-bottom: none;
    }
  }

  .status-name {
    font-weight: 600;
    color: $gray4;
  }

  .overflow-auto {
    overflow: auto;
  }

  .modal-container-product {
    width: 100%;
    height: 90vh;
    overflow: auto;
  }

  .modal-open .modal {
    overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
}

