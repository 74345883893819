@import "../../../../styles/style-variables.scss";

.payment-history {
  font-size: $font-size-14px;

  td {
    vertical-align: middle !important;
  }

  tr {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    border-left: 1px solid $gray1;
    vertical-align: middle;
  }

  th {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
    vertical-align: middle !important;
    height: 3rem;
    border: none;
  }

  .invoice-status-badge {
    border-radius: 0.4rem;
    padding: 0.4rem 1rem;
  }

  .payment-success {
    background-color: $blue1;
    color: $primary;
    .draw-dot {
      background-color: $primary;
    }
  }
  .payment-failed {
    background-color: $red1;
    color: $red;
    .draw-dot {
      background-color: $red;
    }
  }
  .draw-dot {
    content: ".";
    border-radius: 50%;
    height: 0.4rem;
    width: 0.4rem;
    position: absolute;
    top: 0.8rem;
    left: 0.5rem;
  }
}
