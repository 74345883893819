@import "../../../styles/style-variables.scss";

.subscribe-confirm-custom-modal {
  font-size: $font-size-12px;
  display: grid;
  align-items: center;
  height: 85vh;
  min-width: 900px !important;

  .modal-dialog {
  }

  .modal-content {
    border-radius: $font-size-10px;
    .modal-body {
    }
  }

  .subscribe-title {
    flex-direction: column;
    font-size: $font-size-16px;
    text-align: center;
    margin-top: 1rem;

    img {
      width: 20rem;
    }
  }

  .subscribe-footer-cancel {
    width: 23rem;
    text-align: center;
    font-size: $font-size-14px;
  }

  .subscribe-footer-ok {
    font-size: $font-size-14px;
  }

  .note-text {
    color: $gray4;
    font-style: italic;
    font-size: $font-size-12px;
  }

  .app-button-primary {
    margin-top: 2rem;
    margin-left: 10px;
    background-color: $black2;
    width: max-content;
    align-self: center;
  }

  .pricing {
    text-decoration: underline;
    cursor: pointer;
  }

  .header-text-1 {
    display: inline-block;
    width: 35rem;
    font-size: $font-size-20px;
    font-weight: 600;
  }
}
