@import "../../../styles/style-variables.scss";

.create-branch {
  border: 1px solid $gray1;
  box-sizing: border-box;
  box-shadow: 0px 44px 54px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 610px;
  width: 714px;
  background-color: $white;

  .add-branch {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 610px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 50px;
  }
  ul {
    list-style: none;
  }

  li {
    font-style: italic;
    font-weight: 500;
  }

  .create-branch-title {
    font-size: $h3;
    font-weight: 700;
    color: #1e2e42;
  }

  .sub-text-branch {
    font-size: $h5;
    font-weight: 500;
    color: $gray4;
  }

  .staff-image {
    width: 195px;
    height: 192px;
  }

  .add-branch-step2 {
  }

  .branch-add-title {
    padding: 32px 42px 32px 42px;
    color: $black2;
    font-weight: 700;
    border-bottom: 1px solid $gray5;
  }

  .input-container-staff {
    min-height: 30rem;
    padding-top: 0.6rem !important;
  }

  .input-container-staff-list {
    min-height: 30rem;
  }

  .input-container {
    padding: 25px 42px 25px 42px;
  }

  .selection-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray2;
    font-size: $h5;
    font-weight: 400;
    padding: 16px 24px 16px 24px;
    border-radius: 10px;
    margin-right: 1rem;
    color: $black3;
  }

  .selection-card-active {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray2;
    font-size: $h5;
    font-weight: 700;
    padding: 16px 24px 16px 24px;
    border-radius: 10px;
    background: $black2;
    color: $white;
    margin-right: 1rem;
  }

  .selection-card-wrapper {
    flex-direction: row;
    display: flex;
  }

  .app-button-primary {
    min-width: 150px !important;
  }

  .button-wrapper-staff {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    padding: 0 2.7rem;
  }

  .app-button-default {
    font-weight: 500;
    margin-right: 10px;
  }

  .staff-list-card {
    height: 70px;
    border-radius: 10px;
    border: 1px solid $gray1;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .sl-no {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: $h3;
    background-color: $gray5;
    height: 70px;
    width: 100%;
  }

  .staff-name {
    font-weight: 700;
    font-size: $h5;
    color: $black2;
  }

  .staff-role-text {
    font-weight: 700;
    font-size: $font-size-10px;
    color: $yellow;
  }

  .staff-username {
    font-weight: 500;
    font-size: $bodyM;
    color: $gray4;
  }

  .add-another-text {
    font-weight: 600;
    font-size: $bodyR;
    color: $black2;
  }

  .staff-list-title {
    font-weight: 700;
    color: $gray4;
    font-size: $caption;
    letter-spacing: 0.2em;
  }

  .staff-name-spacing {
    padding-top: 16px;
  }

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 57px;
    right: 30px;
  }
}
