@import "../../../styles/style-variables.scss";

.create-product {
}

.customer-setup-modal {
  margin-left: 0;
  margin-right: 0;
  max-width: 100% !important;
  margin-bottom: 0 !important;
  border-radius: none;
  word-break: break-word;

  .small-dot {
    height: 0.2rem;
    width: 0.2rem;
    background: $black2;
    border-radius: 30rem;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.4rem;
    margin-bottom: 0.1rem;
  }

  .full-height {
    height: 100%;
  }

  .price-setup-container {
    width: 80rem;
    margin: auto;
    .name-badge {
      word-break: keep-all;
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-14px;
      width: 3.125rem !important;
      height: 3.125rem !important;
      background-color: $black2 !important;
      color: $white;
      display: table-cell !important;
      vertical-align: middle;
      word-break: keep-all;
    }
  }
  .body-container {
    // margin-left: 5rem;
    // margin-right: 5rem;
  }

  .product-image {
    height: 3.375rem;
    width: 3.375rem;
    border-radius: 0.125rem;
  }

  .modal-body {
    padding: 0;
  }

  .align-center {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .modal-content {
    height: 90vh;
    margin-top: 6rem;
  }

  .create-product-header {
    height: 5.25rem;
    background: $sidebar-bg;
  }

  .add-product-text {
    color: $white;
    font-size: $h2;
    font-weight: 600;
  }

  .remove-icon {
    height: 1.625rem;
    width: 1.625rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .product {
    min-height: 8.5rem;
    border: 1px solid $gray1;
    border-radius: 0.675rem;
    margin-top: 1.5rem;

    .product-name-setup-card {
      color: $black2;
      font-size: $h4;
      font-weight: 500;
      // padding: 1rem;
    }

    .product-code {
      color: $gray4;
      font-size: $bodyM;
      font-weight: 500;
    }

    .product-category {
      color: $black2;
      font-size: $bodyM;
      font-weight: 500;
    }

    .product-bg {
      background-color: $gray5;
      min-height: 2.875rem;
      justify-content: center;
      align-items: center;
    }

    .actual-price {
      color: $gray4;
      font-size: $h5;
      font-weight: 500;
    }

    .footer-pirce {
      font-size: $h5;
      color: $black2;
      font-weight: 700;
    }

    .product-container-card {
      justify-content: center;
      align-items: center;
      min-height: 6.5rem;
      display: flex;
    }

    .price-unit {
      color: $gray4;
      font-size: $bodyM;
      font-weight: 400;
    }
  }

  .popup-close-icon {
    height: 2.25rem;
    // margin-right: 6rem;
    width: 2.25rem;
  }

  .app-search-wrapper-container {
    // max-width: 25rem;
    margin-top: 3rem;

    .app-search-gray {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
      border-radius: 1.25rem;
      background: $gray5;
    }
    .app-search-input-gray {
      border-radius: 0.625rem;
      width: 100%;
      height: 3rem;
      border: none;
      font-size: $font-size-14px;
      background-color: $gray5;
      display: flex;
      flex-direction: row;
    }

    .search-input-box-gray {
      width: 85%;
      border: none;
      background-color: $gray5;
      border-radius: 0.625rem;
    }

    .app-search-input {
      border-radius: 0.625rem;
      width: 100%;
      height: 3rem;
      border: none;
      font-size: $font-size-14px;
      // background-color: $white;
      display: flex;
      flex-direction: row;
    }

    .search-input-box-gray:focus {
      outline: none;
    }

    .search-icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    .border-radius-none {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .search-input-box {
      width: 85%;
      border: none;
      // background-color: $white !important;
      border-radius: 0.625rem;
    }
    .search-input-box-gray:focus {
      outline: none;
    }

    .search-input-box:focus {
      outline: none;
    }

    .search-icon-wrapper {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .search-icon-wrapper-customer {
      width: 6%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .custom-list {
      box-shadow: 0px 26px 20px rgba(30, 46, 66, 0.09);
      background: $white;
      min-height: 3rem;
      position: absolute;
      max-height: 10rem;
      left: 1rem;
      right: 1rem;
      border-bottom-left-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
      z-index: 9999;

      .product-name {
        font-size: $bodyM;
        font-weight: 500;
        padding: 0.5rem;
        cursor: pointer;
        padding: 1rem;
      }

      .product-name-border {
        border-bottom: 1px solid $gray1;
      }
    }
  }

  .customer-container {
    border: 1px solid $gray1;
    background: rgba(242, 243, 245, 0.5);
    min-height: 10rem;
    border-radius: 0.375rem;
    padding: 1rem;
    margin-top: 1.4rem;

    .customer-title {
      font-size: $bodyM;
      color: $black2;
      font-weight: 600;
    }

    .customer-list {
      background: $white;
      border: 1px solid $gray1;
      margin-top: 2rem;
      border-radius: 0.375rem;

      .row-wrapper {
        padding: 1rem;
        margin: 0;
      }

      .row-wrapper:hover {
        box-shadow: 0px 0px 44px rgba(30, 46, 66, 0.12);
      }

      .border-bottom-row {
        border-bottom: 1px solid $gray1;
      }

      .sl-no {
        font-size: $bodyM;
        color: $gray4;
        font-weight: 600;
      }

      .customer-name {
        font-size: $h5;
        color: $black2;
        font-weight: 500;
      }

      .customer-price {
        font-size: $h5;
        color: $gray4;
        font-weight: 700;
      }
    }
  }

  .customer-search {
    position: relative;
  }

  .product-custom {
    left: 0 !important;
    right: 0 !important;
  }

  .selected-customer {
    background: $white;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.375rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;

    .cross-mark {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
    }

    .selected-name {
      font-size: $h5;
      font-weight: 700;
      color: $black2;
    }

    .price-title {
      color: $gray4;
      font-weight: 500;
      font-size: $bodyM;
    }

    .price-card {
      display: inline-block;
      background: $gray5;
      color: $black2;
      font-weight: 700;
      font-size: $h5;
      border-radius: 0.375rem;
      height: 2rem;
    }

    .price-card-input {
      border: none;
      color: $black2;
      font-weight: 700;
      font-size: $h5;
      background: $gray5;
      width: 5rem;
      height: 1.5rem;
      padding: 0;
      margin: 0;
      padding: 0.3rem 0.5rem 0.3rem 0.5rem;
      margin-top: 0.2rem;
    }
    .price-card-input:focus {
      outline: none;
    }

    .add-button {
      display: inline-block;
      padding: 0.2rem 0.5rem 0.2rem 0.5rem;
      background: $primary;
      border-radius: 0.375rem;
      color: $white;
    }
  }

  .customer-focus {
    background: $white;
  }

  .cross-mark {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }

  .setup-product-image-left {
    width: 20%;
    margin-left: 1rem;
  }

  .setup-product-image-right {
    width: 80%;
    padding-left: 1rem;
  }
}
