@import "../../styles/style-variables.scss";

.my-shop {
  font-size: $font-size-14px;
  padding-bottom: 13rem;

  td {
    vertical-align: middle !important;
  }

  tr {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    border-left: 1px solid $gray1;
    vertical-align: middle;
  }

  .fixed-width-6 {
    width: 6rem;
  }

  .fixed-width-8 {
    width: 8rem;
  }

  .fixed-width-4 {
    width: 4rem;
  }

  tr:hover {
    background-color: $gray5;
  }

  .tr-complete {
    background-color: $gray5;
    color: $gray3 !important;

    .order-number {
      color: $gray3;
    }
  }

  .product-search {
    padding: 0 1rem;
    min-width: 18rem;
    height: 2.8rem;
    border: $dark-gray;
    background-color: $white;
    border-radius: 0.625rem;
  }

  .product-search:focus {
    outline: none;
  }

  th {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
    vertical-align: middle !important;
    height: 3rem;
  }

  .th-width-sno {
    width: 5rem;
  }

  .th-fixed-width-10 {
    width: 10rem;
  }

  .th-fixed-width-13 {
    width: 13rem;
  }

  .sub-text {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray4;
  }

  .product-name-max-height {
    max-height: 4rem;
  }

  .cart-btn {
    padding: 0.4rem 1rem;
    width: 7.81rem;
    background-color: $white;
  }

  .cart-input {
    width: 100%;
    border: none;
    text-align: center;
    background-color: transparent;
  }

  .cart-input:focus {
    outline: none !important;
  }

  .add-btn-wrapper {
    border: 1px solid $primary;
  }

  .add {
    background-color: $primary;
    font-weight: 500;
    color: $white;
    padding: 0.4rem auto;
    word-break: keep-all;
  }

  .tr-fixed-5 {
    height: 5rem;
  }

  .view-switch {
    background-color: $white;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    border-radius: 0.375rem;
    float: right;
    padding: 0.8rem 0.5rem;
    border: 1px solid $gray2;
  }

  .view-switch-toggle-active {
    color: $black2;
    font-size: $h5;
    font-weight: 700;
    background: $gray5;
    border-radius: 0.25rem;
  }

  .view-switch-toggle {
    color: $black2;
    font-size: $h5;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-1 {
    flex: 0.6;
  }

  .flex-2 {
    flex: 0.3;
  }

  .flex-2 {
    flex: 0.3;
  }

  .col-min-width-card {
    min-width: 17rem !important;
  }

  .main-filter-badge {
    right: 0.5rem;
    top: 0;
  }

  .view-discount-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray2;
    box-sizing: border-box;
    border-radius: 0.4rem;
    padding: 0.6rem 1rem;
    float: right;

    .filter-text {
      font-weight: 700;
      font-size: $font-size-16px;
    }
  }

  .discount-indicator {
    color: $primary;
    margin-left: 0.2rem;
    position: absolute;
    top: 24px;
  }
  .discount-indicator-1 {
    color: $primary;
    margin-left: 0.2rem;
    position: absolute;
    top: 10px;
    left: 0.8rem;
  }

  .product-card {
    .card-item {
      position: relative;
      width: 16.75rem !important;
      height: 15rem !important;
      display: block;
      background-color: $white;
      border-radius: 0.625rem;
      border: 0.08px solid $gray1;
      box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
      background-color: $white;

      .card-title {
        font-size: $font-size-16px;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      .product-description {
        font-size: $font-size-12px;
        color: $dark-gray;
      }

      .product-img {
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        overflow: hidden;
      }

      .product-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-12px;
        line-height: 0.7rem;
        color: $dark-gray;
      }

      .product-qty {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-12px;
        line-height: 0.7rem;
        color: $primary-black;
      }

      .price {
        background-color: $light-red;
        color: $red;
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-14px;
        line-height: $font-size-14px;
        font-style: normal;
        padding: 0.2rem 0.4rem;
        border-radius: 0.2rem;
      }

      .button {
        font-size: $font-size-14px;
        width: 15rem;
        height: 2.5rem;
        background-color: $white;
        border: 0.1rem solid $gray1;
        border-radius: 0.2rem;
      }

      .button-wrapper {
        position: absolute;
        bottom: 10px;
        left: 25%;
      }
    }

    .pm {
      margin: 0.4rem;
    }

    .offset-1 {
      margin-top: 1rem;
    }

    .center {
      text-align: center;
    }
  }

  .product-table {
    min-height: 70vh !important;
  }
}

.my-shop-filters {
  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    // margin-left: 10.5rem;
    right: 1rem;
    margin-top: -0.5rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .filterpop-over {
    background: $white;
    border: 1px solid rgba(24, 24, 51, 0.07);
    position: absolute;
    border-radius: 0.375rem;
    width: 20rem;
    height: 23.18rem;
    top: 3.2rem;
    text-align: left;
    margin-left: -18rem;
    z-index: 9999;

    box-shadow: 0px 15px 15px rgba(30, 46, 66, 0.25);

    .category-list {
      max-height: 10.5rem;
      min-height: 10.5rem;
      overflow-y: auto;
    }

    .title-pop-over {
      font-size: $h5;
      color: $black2;
      font-weight: 700;
    }
  }

  .filter-header {
    padding: 1.25rem 1.25rem 1.125rem 1.25rem;
    color: $gray3;
    font-size: $caption;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $gray1;
  }

  .filter-search-wrapper {
    height: 2rem;
    background: $gray5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
  }

  .filter-search-icon {
    height: 0.6rem;
    width: 0.6rem;
  }

  .filter-search {
    height: 100%;
    width: 90%;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background: $gray5;
    color: $gray4;
    font-size: $font-size-11px;
    border: none;
  }

  .filter-search:focus {
    outline: none;
  }

  .filter-left {
    padding-left: 1rem;
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-center {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .vendor-filter {
    min-height: 10rem;
    max-height: 10rem;
    overflow-y: auto;
  }

  .filter-right {
    border-bottom: 1px solid $gray1;
    padding-top: 1rem;
  }

  .categories-name {
    color: $black2;
    font-weight: 500;
    font-size: $bodyR;
  }

  .categories-name-active {
    color: $black2;
    font-weight: 600;
    font-size: $bodyR;
  }

  .reset-button {
    width: 4.875rem;
    display: flex;
    height: 2.375rem;
    border: 1px solid $gray2;
    color: $black2;
    font-size: $h5;
    font-weight: 700;
    border-radius: 0.375rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
  }

  .apply-button {
    width: 8.18rem;
    display: flex;
    height: 2.375rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid $primary;
    font-size: $h5;
    font-weight: 700;
    color: $primary;
    cursor: pointer;
  }

  .checkbox {
    height: 0.875rem;
    width: 0.865rem;
  }

  .button-wrapper {
    float: right;
    margin: 0;
    margin-right: 1rem;
  }
}

.price-discount {
  position: relative;

  .price-discount-button-tag {
    position: absolute;
    left: -2.2rem;
    top: -0.4rem;
    height: 3.2rem;
  }

  .price-discount-popover {
    .arrow-up {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem;
      // margin-left: 10.5rem;
      right: 14rem;
      margin-top: -0.5rem;
    }

    .arrow-up::before {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-bottom-color: white;
      border-width: 0 0.5rem 0.5rem 0.5rem;
    }

    .arrow-up::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

    .price-discount-popover-content {
      background: $white;
      border: 1px solid rgba(24, 24, 51, 0.07);
      position: absolute;
      border-radius: 0.375rem;
      width: 24rem;
      height: 23.18rem;
      top: 3.2rem;
      text-align: left;
      margin-left: -23rem;
      z-index: 9999;

      box-shadow: 0px 15px 15px rgba(30, 46, 66, 0.25);

      .discount-indicator {
        color: $primary;
        margin-left: -9px;
        position: absolute;
        top: 18px;
        font-size: 15px;

      }

      .header {
        padding: 1.25rem 1.25rem 1.125rem 1.25rem;
        color: $gray3;
        font-size: $caption;
        // font-weight: 700;
        text-align: left;
        // border-bottom: 1px solid $gray1;

        .main-text {
          font-size: 16px;
        }
      }

      .discount-table-container {
        padding: 0.25rem 1.25rem 1.125rem 1.25rem;

        table {
          width: 100%;

          th {
            border-top: none;
            border-bottom: none;
            color: #1e2e42
          }

          tr {
            border-top: none;
            border-bottom: none;
            border-left: none;
            border-right: none;
          }

          td {
            border-top: none;
          }
        }
      }
    }
  }
}