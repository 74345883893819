@import "../../styles/style-variables.scss";

.login {
  font-size: $font-size-12px;
  min-height: 87vh;
  display: flex;

  .employee-login {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    cursor: pointer;
    font-weight: 600;
  }

  .left-image {
    flex: 40%;
    height: 100%;
    overflow: hidden;

    .login-image {
      position: fixed;
      width: 40%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .right-content {
    flex: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .sub-title {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #e9eaec;
  }

  .login-card {
    max-width: 31.25rem !important;
    min-width: 29.5rem;
    position: relative;
    // border: 1px solid #d5d8dc;
    // border-radius: $font-size-10px;
  }

  .border-card {
    border: 1px solid #d5d8dc;
    border-radius: $font-size-10px;
  }

  .header-text {
    font-size: $font-size-30px;
    font-weight: 300;
  }

  .sub-header-text {
    font-size: $font-size-16px;
    font-weight: 300;
  }

  .click-here {
    color: $primary;
    cursor: pointer;
  }

  .google-login {
    box-sizing: border-box;
    border-radius: $font-size-10px;

    .button-container {
      height: 41px;

      div {
        align-self: center;
      }
    }
  }

  .fb-login {
    display: inline-flex;
    align-items: center;
    width: 400px !important;
    padding: $font-size-10px;
    justify-content: center;
    border: 1px solid #d5d8dc;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    img {
      width: 1.25rem;
    }
  }

  .or {
    margin-top: 01rem !important;
    font-weight: 600;
    color: $dark-gray;
    align-self: center;
    position: relative;
  }

  .line::after {
    width: 10rem;
    height: 1px;
    background: $gray1;
    content: "";
    position: absolute;
    left: 55%;
    top: 0.6rem;
    overflow: hidden;
  }

  .line::before {
    width: 10rem;
    height: 1px;
    background: $gray1;
    content: "";
    position: absolute;
    left: 0;
    top: 0.6rem;
    overflow: hidden;
  }

  .user-image {
    padding: 1.25rem;
    border-radius: 50%;
    background-color: $gray5;
    position: absolute;
    margin-top: -30px;
    margin-left: -30px;
  }

  .forgot {
    font-weight: 500;
  }

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 58px;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }
}