@import "../../../../styles/style-variables.scss";

.customer-invoice-detail {
  margin-left: 0;
  margin-right: 0;
  max-width: 100% !important;
  margin-bottom: 0 !important;
  border-radius: none;
  word-break: break-word;

  .sub-text {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray4;
  }

  table {
    border-radius: 0.125 rem;
    border: 1px solid $gray1;
  }

  .min-width-sm {
    min-width: 5rem;
  }

  .content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  th {
    color: $dark-gray;
    font-weight: 500;
    border: 1px solid $gray1;
    font-size: $font-size-14px;
  }

  td {
    border: 1px solid $gray1;
    vertical-align: middle !important;
  }

  tr {
    border: none !important;
    vertical-align: middle;
  }

  .th-max-width {
    width: 12rem;
  }

  .th-product-max-width {
    width: 30rem;
  }

  .paid-image {
    display: inline-block;
    position: absolute;
    right: 17rem;

    img {
      width: 10rem;
    }
  }

  .small-dot {
    height: 0.2rem;
    width: 0.2rem;
    background: $black2;
    border-radius: 30rem;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.4rem;
    margin-bottom: 0.1rem;
  }

  .name-badge {
    word-break: keep-all;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-14px;
    width: 3.125rem !important;
    height: 3.125rem !important;
    background-color: $black2 !important;
    color: $white;
    display: table-cell !important;
    vertical-align: middle;
    word-break: keep-all;
  }

  .full-height {
    height: 100%;
  }

  .edit-invoice-container {
    width: 80rem;
    margin: auto;
  }

  .body-container {
    // margin-left: 5rem;
    // margin-right: 5rem;
    min-height: 30rem !important;
  }

  .custom-footer {
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 1px solid $gray2;
    border-style: dashed !important;
    min-height: 5rem;
  }

  .add-notes-wrapper {
    display: inline-block;
    font-style: italic;
    font-size: $font-size-14px;
    // padding: 0.625rem;
    // border-radius: 0.3rem;
    // border: 1px solid $gray2;

    .notes {
      color: $green;
    }

    .note {
      color: $gray4;
    }
  }

  .switch-wrapper {
    padding: 0.25rem;
    border-radius: 2rem;
    border: 1px solid $gray2;
    width: 3rem;
    cursor: pointer;

    .switch-body {
      display: flex;
      justify-content: space-between;
    }

    .switch {
      font-size: $font-size-10px;
      padding: 0.25rem;
      font-weight: 500;
      border-radius: 50%;
      text-align: center;
      width: 2rem;
    }

    .active-switch {
      background-color: $primary;
      color: $white;
    }
  }

  .item-tax-input {
    color: $gray4;
    border: none;
    font-size: $font-size-14px;
    width: 100%;
    text-align: center !important;
    padding: 0 0.25rem;
    height: 3rem;
    background-color: transparent !important;
  }

  .item-focus {
    background-color: $blue2;
    border: 1px solid $primary;
    height: 100%;
    width: 100% !important;
    padding: 0.75rem;
  }

  .item-tax-input:focus-visible {
    outline: none !important;
  }

  .tax-input {
    color: $gray4;
    border: 1px solid $gray2 !important;
    font-size: $font-size-14px;
    width: 4rem;
    text-align: right !important;
    padding: 0 0.25rem;
    height: 2rem;
  }

  .tax-input:focus-visible {
    outline: none !important;
    border: 1px solid $gray2 !important;
  }

  .invoice-detail-wrapper-card {
    background-color: $white;
    border-radius: 0.125rem;
    border-top: 0.5rem solid $primary;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }

  .back-icon {
    position: absolute;
    font-size: $font-size-20px;
    color: $primary;
    left: -2rem;
    top: 0.2rem;
    cursor: pointer;
  }

  .invoice-detail-wrapper {
    .invoice-detail {
      display: flex;
      justify-content: space-between;
    }

    .name-badge {
      word-break: keep-all;
      border-radius: 10px;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-14px;
      width: 4rem !important;
      height: 4rem !important;
      display: table-cell !important;
      vertical-align: middle;
      color: $app-black;
      background-color: $gray5 !important;
    }
  }

  .user-name {
    font-weight: 700;
    font-size: $font-size-16px;
  }

  .address {
    font-size: $font-size-14px;
    font-weight: 500;
    color: $dark-gray;
  }

  .discount-text {
    font-size: $font-size-14px;
    font-weight: 500;
    color: $dark-gray;
  }

  .grand-total-wrapper {
    background-color: $gray5;
    padding: 0.625rem 1.25rem;
    border-radius: 0.25rem;
    border: 1px solid $gray2;
  }

  .modal-body {
    padding: 0;
  }

  .align-center {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .modal-content {
    height: 90vh;
    margin-top: 6rem;
  }

  .create-product-header {
    height: 5.25rem;
    background: $sidebar-bg;
  }

  .add-product-text {
    color: $white;
    font-size: $h2;
    font-weight: 600;
  }

  .remove-icon {
    height: 1.625rem;
    width: 1.625rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .pallet-text {
    font-size: $font-size-10px !important;
    margin: 0 !important;
  }

  .flex-1 {
    flex: 0.9;
    padding-right: 0.7rem;
  }

  .flex-2 {
    flex: 0.1;
  }

  .popup-close-icon {
    height: 2.25rem;
    // margin-right: 6rem;
    width: 2.25rem;
  }

  .tab-wrapper {
    border-bottom: 1px solid $gray2;
  }
}

.payment-status {
  color: $red;
  font-size: $font-size-14px;
  font-weight: 500;

  .image-info {
    margin-right: 8px;
  }
}
