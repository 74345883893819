@import "../../home/styles/style-variables.scss";

.profile {
  .flex-1 {
    flex: 0.25;
  }

  .border-blue {
    border: 1px solid $primary;
  }

  .logout-text {
    color: $red;
  }

  .border-right-gray {
    border-right: 1px solid $gray1;
  }

  .lable-text {
    font-weight: 500;
    color: $gray8;
  }

  .profile-icon {
    width: 5rem !important;
    height: 5rem !important;
    font-size: $font-size-22px !important;
  }

  .name-badge {
    word-break: keep-all;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-14px;
    width: 3.125rem;
    height: 3.125rem;
    background-color: $yellow;
    display: table-cell !important;
    vertical-align: middle;
  }

  .payment-card {
    position: relative;
    background-color: $white;
    border-radius: 0.25rem;
    padding: 0 !important;
    margin-top: 1rem;

    .image-wrapper {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .payment-title {
      font-size: $font-size-16px;
      font-weight: 600;
    }

    .payment-description {
      color: $gray4;
      font-weight: 500;
    }

    .continue-button {
      border: 1px solid #d5d8dc;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;

      .radio {
        width: 1.2rem;
      }

      .arrow-right {
        width: 0.5rem;
      }
    }

    .dot-wrapper {
      display: flex;
      width: max-content;
      align-items: center;
      justify-content: center;
    }

    .draw-dot {
      content: ".";
      border-radius: 50%;
      height: 0.4rem;
      width: 0.4rem;
      background-color: $green;
    }

    .active-text {
      color: $green;
    }

    .remove-text {
      color: $red;
      cursor: pointer;
    }

    .pending-text {
      color: $yellow;
    }

    .yellow-dot {
      background-color: $yellow !important;
    }
  }

  .card {
    border: 1px solid $gray1;
  }

  .branch-card {
    border-radius: 0.625rem;
  }

  .cp-flex-1 {
    flex: 0.1;
  }

  .cp-flex-2 {
    flex: 0.9;
  }

  .cp-flex-3 {
    flex: 0.2;
  }

  .branch-flex-1 {
    flex: 0.15;
  }

  .branch-flex-2 {
    flex: 0.75;
  }

  .branch-flex-3 {
    flex: 0.1;
  }
}

.linkpop-over {
  font-size: $font-size-14px;
  position: absolute;
  background-color: $white;
  z-index: 9999;
  width: 20rem;
  right: 0;
  top: 4.5rem;
  border-radius: 0.5rem;

  .account-name {
    font-weight: 600;
    color: $gray4;
  }

  .account-list {
    max-height: 12.5rem;
    overflow-y: auto;
  }

  .account-name:hover {
    color: $app-black;
  }

  .account-title-wrapper {
    padding: 0.75rem;
  }

  .filter-header {
    font-size: $font-size-16px !important;
    padding: 1.25rem 1.25rem 1.125rem 1.25rem;
    color: $app-black !important;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $gray1;
  }

  .apply-button {
    display: flex;
    height: 2.375rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    // border: 1px solid $primary;
    font-size: $h5;
    font-weight: 700;
    color: $primary;
    cursor: pointer;
    width: 100%;
  }

  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    // margin-left: 10.5rem;
    right: 5rem;
    margin-top: -0.5rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
}

.payment-card {
  .status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .bank-account-number-container {
    display: flex;
    flex-direction: row;
  }

  .bank-account-number-text {
    color: $gray4;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .bank-account-number-text-star {
    margin-top: 3px;
  }
}
