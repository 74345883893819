@import "../../../styles/style-variables.scss";

.dropdown-v2 {
  width: 100%;
  position: relative;

  .dropdown-input {
    border-radius: 1.875rem;
    width: 100%;
    height: 1.7375rem;
    background: white;
    border: none;
    font-size: $font-size-12px;
    padding: 1rem;
    padding-right: 2rem;
    color: $app-black !important;
  }

  .dropdown-input-mulit-select {
    border-radius: 1.875rem;
    width: 100%;
    background: white;
    border: none;
    font-size: $font-size-12px;
    color: $app-black !important;
    padding-top: 0.5rem;
    padding-right: 2rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    .input {
      border: none;
      outline: none;
      width: 98%;
    }
    .input-padding {
      padding-left: 0.6rem;
      padding-right: 0.5rem;
      margin: 0.4rem;
    }
    .selected-item {
      font-size: 0.7rem;
      margin-right: 0.2rem;
      margin-bottom: 0.2rem;
      box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.15);
      border-radius: 1rem;
      padding: 0.2rem 0.8rem 0.2rem 0.8rem;
      display: inline-block;

      .selected-delete {
        color: #000;
        font-weight: bold;
        padding-left: 0.5rem;
        font-size: 0.8rem;
        cursor: pointer;
      }

      .selected-text {
        word-break: break-word;
      }
    }
  }

  .dropdown-read-only {
    background-color: $dark-gray;
  }

  .dropdown-input-border-radius {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .dropdown-input-border-radius-mulit-select {
    border-radius: 1.5rem !important;
  }

  .caret {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 1rem;
  }

  .dropdown-input {
    outline: none;
  }

  .dropdown-options {
    background: #ffffff;
    font-size: $font-size-12px;
    position: absolute;
    width: 100%;
    z-index: 9999;
    overflow: hidden;

    .dropdown-options-wrapper {
      max-height: 10rem;
      overflow: auto;

      .dropdown-option {
        //border-top: 1px solid #ecebeb;
        padding: 0.5rem 0.8rem;
        cursor: pointer;
        outline: none;
        word-break: break-word;
      }

      .dropdown-option:hover {
        background-color: rgba(0, 0, 0, 0.01);
      }

      .dropdown-option-active {
        background-color: rgba(0, 0, 0, 0.01);
      }

      .no-result {
        color: #b2aeab;
      }
    }

    .dropdown-options-wrapper::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
    }
    .dropdown-options-wrapper::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.25);
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }
    .dropdown-options-wrapper::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 0;
    }
  }

  .dropdown-options-show-border-radius {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .selectable-dropdown {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;

    .caret {
      position: absolute;
      right: 0;
      padding-right: 1rem;
      z-index: 999;
    }

    .caret:hover {
      cursor: pointer;
    }

    .placeholder {
      color: #b2aeab;
    }
  }

  .placeholder-selected::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black !important;
    opacity: 1; /* Firefox */
  }

  .placeholder-selected:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black !important;
  }

  .placeholder-selected::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black !important;
  }
}
