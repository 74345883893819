@import "../../../styles/style-variables.scss";

.toaster {
  .toaster-wrapper {
    position: fixed;
    z-index: 9999;
    top: 4.0625rem;
    width: 100% !important;
    font-weight: 600;
    color: white;
    min-height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0 !important;
    right: 0 !important;
    animation-name: toaster-animation;
    animation-duration: 1s;
  }

  .toaster-success {
    background-color: $green;
  }

  .toaster-error {
    background-color: $red;
  }

  .toaster-waning {
    background-color: #ffc107;
  }

  .toaster-none {
    background-color: $gray4;
  }

  @keyframes toaster-animation {
    0% {
      opacity: 20%;
      top: 3.125rem;
    }
    50% {
      top: 4.0625rem;
    }
  }
}
