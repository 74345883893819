@import "./style-variables.scss";

.app-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  min-width: 64rem !important;
  max-width: 105rem !important;
  padding: 0 2rem;
}

.app-input {
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  border: none;
  font-size: $font-size-14px;
  padding: 1rem;
  background-color: white;
  border: 1px solid #d5d8dc;
  color: $app-black !important;
}
.app-input-small{
  height: 2rem !important;
}

.app-input-text-area {
  border: 1px solid $gray2;
  border-radius: 0.625rem;
  width: 100%;
  font-size: $caption;
  padding: 1rem;
  font-weight: 400;
}

.otp-input {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: none;
  padding: 1rem;
  background-color: white;
  border: 1px solid #d5d8dc;
  color: $app-black !important;
  font-size: $font-size-16px;
  text-align: center;
  font-weight: 700;
}

.otp-input:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.app-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b2aeab;
  opacity: 1; /* Firefox */
}

.app-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b2aeab;
}

.app-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b2aeab;
}

.app-input-label {
  font-weight: 500;
  font-size: $font-size-12px;
  line-height: 0.8rem;
  padding-bottom: 0.85rem;
  margin-bottom: 0 !important;
  color: $label-black;
  display: inline-block;
}

.otp-input-label {
  font-weight: 700;
  font-size: $font-size-12px;
  line-height: 0.8rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0 !important;
  color: $gray4;
}

.invalid-otp {
  input {
    border: 1px solid $red !important;
  }
}

.bw-spaces-input {
  padding-top: 1rem;
}

.star {
  color: $invalid;
}

.position-relative {
  position: relative !important;
}

.invalid-input-container {
  .app-input-label {
    font-weight: 600;
    color: $invalid;
  }
  .app-input {
    border: 1px solid $invalid;
  }

  .app-input-text-area {
    border: 1px solid $invalid !important;
  }
}

.app-button-primary {
  border: none;
  color: white;
  background: $primary;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}

.app-outline-button {
  border: none;
  color: $black2;
  background: transparent;
  border: 1px solid $gray2;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}

.app-button-primary-outline {
  border: none;
  color: $black2;
  background: $white;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  border: 1px solid $primary;
  word-break: keep-all;
}

.app-button-default {
  border: none;
  color: $black2;
  background: $button-default;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}

.app-button-secondary {
  border: 1px solid $gray1;
  color: $black2;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: 600;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}
.app-button-success {
  border: 1px solid $green;
  color: $black2;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: 600;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
  color: $green;
}

.app-button-red {
  border: 1px solid $red;
  color: $white;
  background-color: $red;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 2.5rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}

.app-button-primary-small {
  border: none;
  color: white;
  background: $primary;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 2rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}

.app-button-disabled-small{
  border: none;
  color: white;
  background: $gray4;
  border-radius: 0.25rem;
  font-size: 0.84rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 2rem;
  transition-duration: 0.4s;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
}

.app-button-secondary-small {
  padding: 0.5rem 2rem;
  border: 0.0625rem solid $gray2;
  border-radius: 0.375rem;
  cursor: pointer !important;
  word-break: keep-all;

  .btn-text {
    color: $gray4;
    font-size: $bodyR;
    font-weight: 500;
  }
}

.app-button-red-small {
  padding: 0.5rem 2rem;
  border: 0.0625rem solid $red;
  border-radius: 0.375rem;
  cursor: pointer !important;
  word-break: keep-all;
  background-color: $red;

  .btn-text {
    color: $white;
    font-size: $bodyR;
    font-weight: 500;
  }
}

.app-button-primary-small:hover {
  border: 0.0625rem solid $primary;
}

.app-button-secondary-small:hover {
  border: 0.0625rem solid $primary;
}

.app-button-disabled {
  background: $gray3 !important;
}

.app-button-primary:hover {
  opacity: 0.8;
}
.app-button-red:hover .app-button-red-small:hover {
  opacity: 0.8;
}

.app-button-red:focus {
  outline: none;
}

.app-button-primary:focus {
  outline: none;
}

.app-button-primary-outline:focus {
  outline: none;
}

.app-button-secondary:hover {
  opacity: 0.8;
}

.app-button-secondary:focus {
  outline: none;
}

.app-button-success:hover {
  opacity: 0.8;
}

.app-button-success:focus {
  outline: none;
}

.header-text {
  font-weight: 600;
  font-size: $font-size-24px;
}

.app-header-title {
  font-weight: 600;
  font-size: $font-size-22px;
}

.app-header-title-18 {
  font-weight: 600;
  font-size: $font-size-18px;
}

.no-padding {
  padding: 0 !important;
}

.h1 {
  font-size: $h1;
}

.h2 {
  font-size: $h2;
}

.h3 {
  font-size: $h3;
}

.h4 {
  font-size: $h4;
}

//ICONS
.icon-5 {
  width: 0.3215rem;
}

.icon-8 {
  width: 0.5rem;
}

.icon-10 {
  width: 0.625rem;
}

.icon-12 {
  width: 0.75rem !important;
}

.icon-15 {
  width: 0.9375rem !important;
}

.icon-20 {
  width: 1.25rem !important;
}

.icon-30 {
  width: 30px;
}

.icon-40 {
  width: 2.25rem;
}

.icon-50 {
  width: 3.125rem;
}

.icon-80 {
  width: 5rem !important;
}

.icon-100 {
  width: 6.25rem !important;
}

.image-circle-wrapper-50 {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.125rem !important;
  overflow: hidden;
  img {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.image-circle-wrapper-80 {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 5rem;
    height: 5rem;
  }
}

.cancel {
  cursor: pointer;
  color: $gray4;
}

.resend {
  font-weight: 700;
  cursor: pointer;
}

.complete {
  font-weight: 600;
  font-size: $font-size-16px;
  .name {
    color: $gray4;
  }
}

.display-grid-center {
  display: grid;
  align-items: center;
  justify-content: center;
}

.title {
  color: $dark-gray;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 14px;
}

.sub-title {
  font-size: $font-size-36px;
  color: #1e2e42;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
}

.filter-arrow {
  border: solid $primary;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 0.18rem;
  margin-left: 0.5rem;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  margin-bottom: 0.1rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.custom-error-tooltip {
  .tooltip-inner {
    max-width: 300px !important;
    background-color: $red1 !important;
    color: $red !important;
    font-style: italic;
    font-size: $font-size-10px;
    font-weight: 500;
    padding: 8px 15px;
  }

  .arrow::before {
    border-right-color: $red1 !important;
    border-left-color: $red1 !important;
  }
}

.font-gray {
  color: $gray4;
}

//Font
.font-14 {
  font-size: $font-size-14px;
}

.font-16 {
  font-size: $font-size-16px;
}

.font-18 {
  font-size: $font-size-18px;
}

.font-20 {
  font-size: $font-size-20px;
}

.semi-bold-text {
  font-weight: 600;
}

.medium-text {
  font-weight: 500;
}

.bold-text {
  font-weight: 700;
}

.vertical-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.filter-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray2;
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  max-width: 8rem;
  float: right;
  .filter-text {
    font-weight: 700;
    font-size: $font-size-16px;
  }
}

.app-search-input {
  border-radius: 0.625rem;
  width: 100%;
  height: 3rem;
  border: none;
  font-size: $font-size-14px;
  background-color: $gray5;
  display: flex;
  flex-direction: row;

  .search-icon-wrapper {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-input-box {
    width: 85%;
    border: none;
    background-color: $gray5;
    border-radius: 0.625rem;
  }

  .search-input-box:focus {
    outline: none;
  }

  .search-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.edit-date-button {
  border: none;
  background-color: transparent;
}

.edit-date-button:focus {
  outline: none;
}

//animations

.animate-image-1 {
  animation-name: animate-img-1;
  animation-duration: 1s;
}

.border-top {
  border-top: 1px solid $gray5;
}

.flex-auto {
  flex: auto !important;
}

@keyframes animate-img-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.app-main-class {
  min-width: 65rem;
  word-break: break-word;
}

.no-records-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 60vh;
  align-items: center;
  margin: auto;
  max-width: 30rem;
}

.badge-red {
  background-color: $red;
  font-weight: 700;
  font-size: $font-size-10px;
  color: $white;
  padding: 0.15rem 0.4rem;
  border-radius: 1rem;
  display: inline-block;
}

.top-0 {
  top: 0;
}

.max-height-4rem {
  max-height: 4rem;
}

.product-max-width {
  width: 30rem;
}

.description-max-width {
  width: 20rem;
}

.app-box-shadow {
  box-shadow: 0px 15px 15px rgba(30, 46, 66, 0.25);
}

.fit-content {
  max-width: fit-content !important;
}

.strike-text{
  text-decoration:line-through;
}

.v-align-base{
  vertical-align: baseline !important;
}


.active-title{
  font-size: 1.125rem ;
}