@import "../../home/styles/style-variables.scss";

.app-header {
  height: 4rem;
  position: sticky;
  width: 100%;
  z-index: 1000;
  top: 0;
  padding: 0 2rem;
  border-bottom: 1px solid $gray1;
  font-size: $font-size-14px;

  .branch-name {
    position: absolute;
    z-index: 9999;
    left: 21rem;
    top: 15px;
    display: flex;
    text-align: center;
  }

  .logo-image {
    overflow: hidden;
    max-height: 3rem;
    margin-right: 0.5rem;

    img {
      //width: 100%;
      max-height: 3rem;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .user-group-icon {
    width: 2rem;
 }
  .app-search-input {
    border-radius: 0.625rem;
    width: 100%;
    min-width: 25rem;
    height: 3rem;
    border: none;
    font-size: $font-size-14px;
    background-color: $white;
    display: flex;
    flex-direction: row;
  }

  .red-badge {
    display: flex;
    height: 1.12rem;
    min-width: 1.12rem;
    padding: 0.2rem;
    padding: 0.3rem;
    font-size: $font-size-10px;
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    background: $red;
    font-weight: 700;
  }

  .app-search {
    border-radius: 1.25rem;
    background: white;
  }

  .search-input-box:focus {
    outline: none;
  }

  .search-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .app-search-gray {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 1.25rem;
    background: $gray5;
  }

  .app-search-input-gray {
    border-radius: 0.625rem;
    width: 100%;
    min-width: 25rem;
    height: 3rem;
    border: none;
    font-size: $font-size-14px;
    background-color: $gray5;
    display: flex;
    flex-direction: row;
  }

  .search-input-box-gray {
    width: 85%;
    border: none;
    background-color: $gray5;
    border-radius: 0.625rem;
  }
  .search-input-box-gray:focus {
    outline: none;
  }

  .search-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .search-icon-wrapper {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-input-box {
    width: 85%;
    border: none;
    background-color: $white !important;
    border-radius: 0.625rem;
  }
  .icon-circle {
    height: 3rem;
    width: 3rem;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray5;
  }

  .platinum-wrapper {
    min-width: 12rem;
  }

  .flag-icon {
    width: 1rem;
    height: 1rem;
  }

  .bell-icon {
    height: 1.2rem;
    width: 1.2rem;
  }

  .actions-wrapper {
    justify-content: right;
    align-items: center;
    float: right;
  }

  .user-circle {
    height: 3rem;
    width: 3rem;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-green;

    .user-name-new {
      color: $dark-green;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .divider {
    color: $gray4;
    margin-left: 1.5rem;
  }

  .toggle-label {
    font-weight: 700;
    font-size: $caption;
    color: $black2;
  }

  .customer-list {
    position: absolute;
    background-color: $white;
    min-width: 25rem;
    max-height: 40rem;
    overflow-y: auto;
    width: 100%;
    border-bottom-left-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
  }

  .staff-name {
    font-weight: 700;
    font-size: $font-size-14px;
    color: $black2;
  }
  .staff-username {
    font-weight: 500;
    font-size: $bodyM;
    color: $gray4;
  }

  .staff-name-spacing {
    padding-top: 16px;
  }

  .app-button-primary {
    padding: 0.3rem 0.6rem;
  }

  .app-button-primary-outline {
    padding: 0.3rem 0.6rem;
    background-color: $blue1;
  }

  .bottom-border-radius-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    margin-left: 10.5rem;
    margin-top: -0.5rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .arrow-up-wrapper {
    width: 0;
    height: 0;
    border: solid $gray5;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.3rem;
    margin: auto;
    margin-top: -8px;

    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .invitaions-card {
    position: absolute;
    right: 14rem;
    top: 4rem;
    width: 22rem;
    min-height: 24.375rem;
    background-color: $white;
    margin-left: -9.5rem;
    border-radius: $font-size-10px;
    border: 1px solid $gray1;

    .invitation-list {
      height: 100%;
      max-height: 20rem;
      overflow-y: auto;
    }

    .status-switch {
      background-color: $gray5;
      width: 12.625rem;
      height: 2rem;
      border-radius: 0.375rem;
      padding: 0.1rem;
      margin: auto;
    }

    .status-switch-toggle {
      color: $gray4;
      font-size: $font-size-14px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .status-switch-toggle-active {
      color: $black2;
      font-size: $font-size-14px;
      font-weight: 700;
      background: $white;
      border-radius: 0.375rem;
    }

    .border-top {
      border-top: 1px solid $gray1;
    }

    .date {
      font-size: $font-size-12px;
      color: $gray4;
    }

    .reject {
      color: $gray4;
      padding-right: 0.5rem;
      border-right: 1px solid $gray1;
    }

    .accept {
      padding-left: 0.5rem;
      color: $green;
    }

    .no-invitation {
      padding-top: 4rem;
      img {
        width: 7.25rem;
      }
    }
  }

  .custom-control-switch {
    .custom-control-label::before {
      border-color: $green !important;
      background-color: $green !important;
    }
    .custom-control-label::after {
      background-color: $white !important;
    }
  }

  .membership-title {
    font-size: $font-size-12px;
    color: $gray4;
  }

  .upgrad {
    text-decoration: underline;
    cursor: pointer;
  }
}

.menu-custom-modal {
  background-color: rgba(0, 0, 0, 0.5);
  height: 110%;
  width: 100%;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
}

.pop-over-menu {
  .menu-card {
    font-size: $font-size-14px;
    min-height: 15.5rem;
    max-height: 25rem;
    min-width: 20rem;
    background: $white;
    border-radius: 0.375rem;
    top: 4rem;
    border: 2px solid $gray5;
    position: absolute;
    right: 1rem;
    overflow-y: auto;
  }
  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    margin-left: 10.5rem;
    margin-top: -0.5rem;
    right: 1.5rem;
    top: 4rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .arrow-up-wrapper {
    width: 0;
    height: 0;
    border: solid $gray5;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.3rem;
    margin: auto;
    margin-top: -8px;

    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .staff-name {
    font-weight: 700;
    font-size: $font-size-14px;
    color: $black2;
  }
  .staff-username {
    font-weight: 500;
    font-size: $bodyM;
    color: $gray4;
  }

  .tick {
    color: $primary;
  }

  .menu-footer-item {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .display-none {
    display: none !important;
  }
}


.notification {
  .no-image {
    width: 10rem;
  }

  .no-text {
    max-width: 50%;
    display: inline-block;
  }
}

.name-badge-1 {
  display: flex !important;
  justify-content: center;
  margin: auto;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: $font-size-14px;
  width: 3.125rem !important;
  height: 3.125rem !important;
  background-color: $yellow;
  word-break: keep-all;
}

.pop-over-notification {
  .menu-card {
    font-size: $font-size-14px;
    min-height: 15.5rem;
    max-height: 25rem;
    min-width: 20rem;
    background: $white;
    border-radius: 0.375rem;
    top: 4rem;
    border: 2px solid $gray5;
    position: absolute;
    right: 12rem;
    overflow-y: auto;
  }
  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    margin-left: 10.5rem;
    margin-top: -0.5rem;
    right: 21rem;
    top: 4rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .arrow-up-wrapper {
    width: 0;
    height: 0;
    border: solid $gray5;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.3rem;
    margin: auto;
    margin-top: -8px;

    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .staff-name {
    font-weight: 700;
    font-size: $font-size-14px;
    color: $black2;
  }
  .staff-username {
    font-weight: 500;
    font-size: $bodyM;
    color: $gray4;
  }

  .tick {
    color: $primary;
  }

  .menu-footer-item {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .display-none {
    display: none !important;
  }
}

.pop-over-swich-branch {
  .menu-card {
    font-size: $font-size-14px;
    min-height: 15.5rem;
    max-height: 25rem;
    min-width: 20rem;
    background: $white;
    border-radius: 0.375rem;
    top: 2.5rem;
    border: 2px solid $gray5;
    position: absolute;
    left: 15rem;
    overflow-y: auto;
  }
  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    margin-left: 10.5rem;
    margin-top: -0.5rem;
    left: 13rem;
    top: 2.5rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .arrow-up-wrapper {
    width: 0;
    height: 0;
    border: solid $gray5;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.3rem;
    margin: auto;
    margin-top: -8px;

    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .staff-name {
    font-weight: 700;
    font-size: $font-size-14px;
    color: $black2;
  }
  .staff-username {
    font-weight: 500;
    font-size: $bodyM;
    color: $gray4;
  }

  .tick {
    color: $primary;
  }

  .menu-footer-item {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

 
}

.display-none {
  display: none !important;
}