@import "../../../styles/style-variables.scss";

.loading-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #35353547;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;

  .loading {
    width: 14rem;
    height: 13.5rem;
    border-radius: 22px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;

    .loading-header {
      position: absolute;
      top: 0;
    }
    .loading-footer {
      position: absolute;
      bottom: 0;
    }
    .loading-gif {
      width: 3.56rem;
      height: 3.313rem;
      object-fit: contain;
    }
  }
}
