@import "../../../styles/style-variables.scss";

.bill-summary {
  font-size: $font-size-14px;

  .arrow-left {
    height: 1.5rem;
    width: 1.5rem;
  }

  .bill-info-container {
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    border-radius: 10px;
    background: $white;
    border: 1px solid $gray1;
    margin-left: 4.5rem;
    min-height: 5rem;
    margin-top: 1.875rem;
    position: relative;
    padding: 36px 22px;
    margin-top: 2.375rem;

    .bill-number {
      font-weight: 700;
      font-size: 18px;
      color: $black2;
    }

    .sub-text {
      color: $gray3;
      font-weight: 500;
      font-size: 11px;
      margin-bottom: 3px;
    }

    .fee-table {
      min-width: 65%;
      margin-top: 20px;
      border-collapse: separate;
      // border-spacing: 0 12px;

      td {
        font-weight: 500;
        font-size: 14px;
        color: $black2;
        padding-top: 22px;
      }

      .card-number {
        color: $gray3;
        font-weight: 500;
        font-size: 9px;
        padding-top: 0;
      }
    }

    .gross-order-value {
      border: 1px solid #d5d8dc;
      border-radius: 4px;
      padding: 14px 18px;

      .gross-order-title {
        text-align: center;
        color: $gray8;
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 5px;
      }

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 14px;

        td {
          color: $dark-gray;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }

    .profile-info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .name-badge {
        word-break: keep-all;
        border-radius: 10px;
        text-align: center;
        font-weight: bold;
        font-size: $font-size-14px;
        width: 64px !important;
        height: 64px !important;
        background-color: $gray5 !important;
        color: $app-black;
        display: table-cell !important;
        vertical-align: middle;
        word-break: keep-all;
      }

      .profile-info {
        display: flex;
        flex-direction: row;

        .line1 {
          color: $black2;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 0;
        }

        .line2,
        .line3 {
          color: $gray4;
          font-weight: 500;
          font-size: 10px;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }

    }
  }

  .small-description-container {
    display: flex;
    flex-direction: row;
  }

  .small-description {
    color: $gray3;
    font-weight: 500;
    font-size: 10px;
  }

  .bill-details-container {
    margin-left: 4.5rem;
    margin-top: 2.375rem;

    .bill-details-table {
      margin-top: 10px;
      border-collapse: separate;
      border-spacing: 0 5px;

      th,
      td {
        border-top: none;
        border-bottom: none;
      }

      thead {
        th {
          font-size: $font-size-14px;
          color: $gray4;
          font-weight: 500;
          line-height: 14px;
          padding: 15px 35px;
        }
      }

      tbody {
        tr:first-child {
          td {
            border-top: 1px solid #dee2e6;
          }
        }

        tr:last-child {
          td {
            border-top: 1px solid #dee2e6;
          }
        }

        tr {
          border-radius: 6px;

          td {
            font-size: $font-size-14px;
            color: $black2;
            font-weight: 500;
            line-height: 16px;
            padding: 25px 35px;
          }
        }
      }

      .right-icon {
        width: 9px;
        height: 18px;
      }
    }
  }

  .order-status-drop-down {
    position: relative;
    margin-top: 15px;

    .selected-order-status {
      color: $primary;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      width: -moz-fit-content;
      width: fit-content;
    }

    .drop-down-arrow {
      height: 8px;
      margin-left: 1.6rem;
    }

    .drop-down-popover {
      font-size: $font-size-14px;
      position: absolute;
      background-color: $white;
      z-index: 9999;
      width: 20rem;
      left: 0;
      top: 40px;
      border-radius: 0.5rem;

      .status-name {
        font-weight: 600;
        color: $gray4;
      }

      .status-list {
        max-height: 12.5rem;
        overflow-y: auto;
      }

      .status-name:hover {
        color: $app-black;
      }

      .status-title-wrapper {
        padding: 0.75rem;
        border-bottom: 1px solid #fff5f5;
      }

      .status-title-wrapper:last-child {
        border-bottom: none;
      }

      .arrow-up {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
        // margin-left: 10.5rem;
        left: 2rem;
        margin-top: -0.5rem;
      }

      .arrow-up::before {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-bottom-color: white;
        border-width: 0 0.5rem 0.5rem 0.5rem;
      }

      .arrow-up::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
      }
    }
  }

  .border-top-dark {
    border-top: 1px solid #868686;
  }

  .half-padding {
    td {
      padding-top: 11px !important;
    }
  }

  .payment-method-table {
    width: 100%;
    margin-top: 10px;
    border-collapse: separate;

    td {
      font-weight: 500;
      font-size: 14px;
      color: $black2;
      padding-top: 22px;
    }

    .card-number {
      color: $gray3;
      font-weight: 500;
      font-size: 9px;
      padding-top: 0;
    }
  }

  .center-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}