@import "../../../../styles/style-variables.scss";

.billing {
  box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
  border-radius: 0.625rem;
  background: $white;
  border: 1px solid $gray1;
  padding: 0 2rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  font-size: $font-size-14px;

  .bill-summary-table {
    border-collapse: separate;
    border-spacing: 0 14px;

    th,
    td {
      border-top: none;
      border-bottom: none;
    }

    thead {
      th {
        font-size: $font-size-14px;
        color: $gray4;
        font-weight: 500;
        line-height: 14px;
        padding: 15px 35px;
      }
    }

    tbody {
      td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      tr {
        border-radius: 6px;
        background: #f2f3f5;
        cursor: pointer;

        td {
          color: $black2;
          font-weight: 500;
          font-size: $font-size-14px;
          line-height: 1rem;
          padding: 25px 35px;
        }
      }
    }

    .right-icon {
      width: 9px;
      height: 18px;
    }
  }
}
