@import "../../../styles/style-variables.scss";

.app-footer {
  height: 4rem;
  width: 100%;
  min-width: 64rem;
  z-index: 1000;
  bottom: 0;
  padding: 0 2rem;
  border-top: 1px solid $gray1;

  .logo {
    padding: 0.8rem 0 0 0rem;
    height: 3.2rem;
  }

  .text-1 {
    font-size: 0.7rem;
    letter-spacing: 0.01;
    color: $app-black;
    cursor: pointer;
    padding-right: 0.2rem;
    color: #78828e;
  }

  .text {
    font-size: 0.7rem;
    letter-spacing: 0.01;
    color: $app-black;
    cursor: pointer;
    padding-right: 0.5rem;
  }

  .text-icon {
    height: 2rem;
    cursor: pointer;
  }

  .bg-white {
    background-color: white;
  }

  .profile-menu {
    background-color: white;
    position: absolute;
    font-size: 0.7rem;
    box-shadow: 0px 10px 40px -18px rgba(0, 0, 0, 0.33);
  }

  .count-badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #21b8c7;
    font-weight: 600;
    color: white;
  }

  .footer-line {
    color: $gray1;
  }
}
