@import "../../../styles/style-variables.scss";

.vendors {
  font-size: $font-size-14px;

  .order-number {
    font-weight: 700;
    font-size: $font-size-14px;
  }

  .semi-bold-text {
    font-weight: 600;
  }

  .bold-text {
    font-weight: 800;
  }

  .load-more {
    bottom: 0rem;
    color: $black2;
    font-weight: 600;
    font-size: $caption;
    padding-bottom: 2rem;
  }

  .reject {
    color: $gray4;
    padding-right: 0.5rem;
    border-right: 1px solid $gray1;
  }

  .accept {
    padding-left: 0.5rem;
    color: $green;
  }

  .action-td{
    min-width: 275px;
  }

  .accept-wrapper{
    position: absolute;
    width: 400px;
    height: 100px;
    margin-left: -20rem;
    margin-top: 1rem;
  }

  .slno-max-width {
    width: 4rem;
  }

  .name-max-width {
    min-width: 25rem;
    max-width: 25rem;
  }

  .role-max-width {
    min-width: 15rem;
  }

  .action-button-width {
    width: 12rem;
  }

  td {
    vertical-align: middle !important;
  }

  tr {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    border-left: 1px solid $gray1;
    vertical-align: middle;
  }

  .tr-complete {
    background-color: $gray5;
    color: $gray3 !important;
    .order-number {
      color: $gray3;
    }
  }

  th {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
    vertical-align: middle !important;
    height: 3rem;
  }

  .staff-row:hover {
    background-color: $gray1;
    cursor: pointer;
  }

  .staff-name-max-height {
    max-height: 4rem;
  }

  .staff-code {
    color: $gray4;
    font-size: $bodyR;
    font-weight: 500;
  }
}

.app-staff-custom-modal {
  .modal-body {
    padding: 0 !important;
  }
}

.staff-password-change {
  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 3.6rem;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }
}
