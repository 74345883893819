@import "../../styles/style-variables.scss";

.forgot-password {
  font-size: $font-size-12px;
  display: grid;
  vertical-align: middle;
  align-items: center;
  min-height: 87vh;
  padding: 2rem;

  .forgot-password-wrapper {
    max-width: 26.25rem;
  }

  .lock-image {
    padding: 1.25rem;
    border-radius: 50%;
    background-color: $gray5;
  }

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 1.875rem;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }
}
