@import "../../../styles/style-variables.scss";

.product {
  .card-item {
    width: 19.57rem !important;
    min-height: 17.8rem !important;
    display: block;
    background-color: white;
    border-radius: 0.625rem;
    border: 0.08px solid #e9eaec;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    background-color: white;
    padding: 1.5rem;
    position: relative;

    .product-item-container {
      display: flex;
      align-items: center;
    }

    .product-item-container-left {
      width: 25%;
    }

    .product-item-container-right {
      width: 75%;
    }

    .category-nmae {
      display: inline-block;
      color: $black2;
      background: $gray5;
      padding: 0.5rem 0.75rem;
      border-radius: 0.375rem;
      font-size: $bodyR;
      font-weight: 500;
    }

    .category-alignment {
      display: flex;
      align-items: center;
    }

    .category-container {
      padding-top: 1.5rem;
    }

    .category-title {
      color: $gray3;
      letter-spacing: 0.1em;
      font-size: $font-size-10px;
      font-weight: 700;
    }

    .card-title {
      font-size: $h3;
      text-overflow: ellipsis;
      overflow-x: hidden;
      color: $black2;
      font-weight: 700;
    }

    .category-devider {
      height: 1rem;
      width: 0.1rem;
      background: $gray1;
      display: inline-block;
      margin-left: 2rem;
      margin-right: 1.8rem;
    }

    .product-img {
      width: 3rem;
      height: 3rem;
      border-radius: 1.5rem;
      overflow: hidden;
    }

    .product-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: $bodyR;
      line-height: 0.8rem;
      color: $dark-gray;
    }

    .title-spacing {
      padding-top: 1.25rem;
    }

    .product-qty {
      font-style: normal;
      font-weight: 500;
      font-size: $bodyR;
      line-height: 0.8rem;
      color: $black2;
    }

    .price {
      background-color: $light-red;
      color: $red;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-14px;
      line-height: $font-size-14px;
      display: flex;
      align-items: center;
      font-style: normal;
      justify-content: center;
      padding: 0.3rem;
      border-radius: 0.375rem;
    }

    .button {
      font-size: $font-size-14px;
      width: 100%;
      height: 2.5rem;
      background-color: white;
      border: 0.1rem solid #e9eaec;
      border-radius: 0.2rem;
      margin-top: 1rem;
    }
  }

  .pm {
    margin: 0.4rem;
  }

  .offset-1 {
    margin-top: 1rem;
  }

  .center {
    text-align: center;
  }

  .price-setup-button {
    font-size: $bodyR;
    font-weight: 600;
    color: $black2;
  }

  .price-setup-button-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .price-setup-button:hover {
    color: $primary;
    border: 1px solid $primary;
  }
}
