/** FONTS */
$font-size-8px: 0.4rem;
$font-size-10px: 0.6rem;
$font-size-11px: 0.6875rem;
$font-size-12px: 0.75rem;
$font-size-13px: 0.78rem;
$font-size-14px: 0.875rem;
$font-size-15px: 0.9375rem;
$font-size-16px: 1rem;
$font-size-17px: 1.02rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-22px: 1.375rem;
$font-size-24px: 1.5rem;
$font-size-28px: 1.68rem;
$font-size-29px: 1.74rem;
$font-size-30px: 1.92rem;
$font-size-32px: 2rem;
$font-size-36px: 2.24rem;

$h1: 2.24rem;
$h2: 1.5rem;
$h3: 1.25rem;
$h4: 1.125rem;
$h5: 1rem;
$bodyR: 0.875rem;
$bodyM: 0.875rem;
$caption: 0.75rem;

/** COLORS */
$white: #ffffff;
$label-black: #78828e;

$primary: #1778f2;

/**  LAYOUT */
$screen-bg: #f5f6fa;
$sidebar-bg: #191634;
$app-black: #000000;
$app-blue: #5547a3;
$gray1: #e9eaec;
$gray2: #d5d8dc;
$gray3: #999fa7;
$gray4: #78828e;
$gray5: #f2f3f5;
$gray6: #f4f4f4;
$gray7: #f5f7f7;
$gray8: #71758b;
$dark-gray: #78828e;
$invalid: #ff3f6c;
$black2: #1e2e42;
$black3: #12193d;
$yellow: #f2b94a;

$yellow1: #fdf6e7;
$blue1: #e7f1fe;

$primary-black: #1e2e42;

$blue2: #177af21a;

$green: #03a685;
$green1: #e6fefa;
$red: #ff3f6c;
$light-red: rgba(233, 86, 39, 0.1);
$button-default: #f2f3f5;

$red1: #ffe6eb;
$button-default: #f2f3f5;
$light-green: #d5e9e8;
$dark-green: #0b5f4e;
