@import "../../../styles/style-variables.scss";

.customer-orders {
  .search-input {
    background: $white;
    border: 1px solid $gray1;
    height: 3rem;
    width: 25rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
  }

  font-size: $font-size-14px;

  .order-number {
    font-weight: 700;
    font-size: $font-size-14px;
  }

  .menu-button {
    display: inline-block;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }

  .semi-bold-text {
    font-weight: 600;
  }

  .bold-text {
    font-weight: 800;
  }

  td {
    vertical-align: middle !important;
  }

  tr {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    border-left: 1px solid $gray1;
    vertical-align: middle;
  }

  .tr-complete {
    background-color: $gray5;
    color: $gray3 !important;

    .order-number {
      color: $gray3;
    }
  }

  th {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
    vertical-align: middle !important;
    height: 3rem;
  }

  .created-date {
    color: $gray3;
  }

  .load-more {
    bottom: 0rem;
    color: $black2;
    font-weight: 600;
    font-size: $caption;
    padding-bottom: 2rem;
  }

  .filter-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray2;
    box-sizing: border-box;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    max-width: 8rem;
    float: right;

    .filter-text {
      font-weight: 700;
      font-size: $font-size-16px;
    }
  }

  .order-status-badge {
    border-radius: 0.4rem;
    padding: 0.4rem 1rem;
  }

  .order-new {
    background-color: $green;
    color: white;

    .draw-dot {
      background-color: white;
    }
  }

  .order-due {
    background-color: $red1;
    color: $red;

    .draw-dot {
      background-color: $red;
    }
  }

  .order-pending {
    background-color: $yellow1;
    color: $yellow;

    .draw-dot {
      background-color: $yellow;
    }
  }

  .order-partial {
    background-color: $blue1;
    color: $primary;

    .draw-dot {
      background-color: $primary;
    }
  }

  .order-complete {
    background-color: $gray1;
    color: $gray4;

    .draw-dot {
      background-color: $gray4;
    }
  }

  .draw-dot {
    content: ".";
    border-radius: 50%;
    height: 0.4rem;
    width: 0.4rem;
    position: absolute;
    top: 0.8rem;
    left: 0.5rem;
  }
}

.search-input-order {
  background: $white;
  border: 1px solid $gray1;
  height: 3rem;
  width: 25rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;

  .search-input-input {
    border: none;
    height: 100%;
    width: 90%;
    padding: 1rem;
    border-radius: 0.625rem;
    font-size: $bodyR;
  }

  .search-input-input:focus {
    outline: none;
  }

  .product-list-search {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.order-filters {
  .filterpop-over {
    background: $white;
    border: 1px solid rgba(24, 24, 51, 0.07);
    position: absolute;
    border-radius: 0.375rem;
    min-width: 16rem;
    height: fit-content;
    top: 3rem;
    text-align: left;
    margin-left: -12rem;
    z-index: 9999;

    .category-list {}

    .title-pop-over {
      font-size: $h5;
      color: $black2;
      font-weight: 700;
    }
  }

  .filter-header {
    padding: 1.25rem 1.25rem 1.125rem 1.25rem;
    color: $gray3;
    font-size: $caption;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $gray1;
  }

  .filter-search-wrapper {
    height: 2rem;
    background: $gray5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
  }

  .filter-search-icon {
    height: 0.6rem;
    width: 0.6rem;
  }

  .filter-search {
    height: 100%;
    width: 90%;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background: $gray5;
    color: $gray4;
    font-size: $font-size-11px;
    border: none;
  }

  .filter-search:focus {
    outline: none;
  }

  .filter-left {
    padding-left: 1rem;
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-center {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-right {
    border-bottom: 1px solid $gray1;
    padding-top: 1rem;
  }

  .down-arrow {
    height: 0.5rem;
    width: 0.7rem;
  }

  .categories-name {
    color: $black2;
    font-weight: 500;
    font-size: $bodyR;
  }

  .reset-button {
    width: 4.875rem;
    display: flex;
    height: 2.375rem;
    border: 1px solid $gray2;
    color: $black2;
    font-size: $h5;
    font-weight: 700;
    border-radius: 0.375rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
  }

  .apply-button {
    display: flex;
    height: 2.375rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid $primary;
    font-size: $h5;
    font-weight: 700;
    color: $primary;
    cursor: pointer;
    width: 100%;
  }

  .checkbox {
    height: 0.875rem;
    width: 0.865rem;
  }

  .button-wrapper {
    float: right;
    margin: 0;
    margin-right: 1rem;
  }


  .tab {
    font-size: $font-size-16px;
    display: inline-block;
    cursor: pointer;
  }

  .active-tab {
    font-size: $font-size-18px;
    font-weight: 600;
    border-bottom: 0.25rem solid $primary;
  }

  .tab-drop-down {
    .down-arrow {
      margin-left: 0.5rem;
    }
  }

  .drop-down-tab-pop-over {
    font-size: $font-size-14px;
    position: absolute;
    background-color: $white;
    z-index: 9999;
    width: 20rem;
    top: 3rem;
    border-radius: 0.5rem;

    .account-name {
      font-weight: 600;
      color: $gray4;
    }
    .selected.account-name {
      color: $app-black;
    }

    .account-list {
      max-height: 12.5rem;
      overflow-y: auto;
    }

    .account-name:hover {
      color: $app-black;
    }

    .account-title-wrapper {
      padding: 0.75rem;
    }

    .filter-header {
      font-size: $font-size-16px !important;
      padding: 1.25rem 1.25rem 1.125rem 1.25rem;
      color: $app-black !important;
      font-weight: 700;
      text-align: left;
      border-bottom: 1px solid $gray1;
    }

    .apply-button {
      display: flex;
      height: 2.375rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      // border: 1px solid $primary;
      font-size: $h5;
      font-weight: 700;
      color: $primary;
      cursor: pointer;
      width: 100%;
    }

    .arrow-up {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem;
      // margin-left: 10.5rem;
      left: 1rem;
      margin-top: -0.5rem;
    }

    .arrow-up::before {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-bottom-color: white;
      border-width: 0 0.5rem 0.5rem 0.5rem;
    }

    .arrow-up::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  .tab-row-right {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
  }

  .calendar-icon {
    height: 3rem;
    margin-right: 1.5rem;
    cursor: pointer;
  }

  .calendar-icon:active {
    opacity: 0.7;
  }
}