@import "../../../styles/style-variables.scss";

.top-sales {
  .card {
    min-height: 30rem;
  }

  .flex-1 {
    flex: 0.2;
  }
  .flex-2 {
    flex: 0.8;
  }

  th {
    background-color: $gray1;
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
  }

  .sales-image {
    width: 10rem;
  }

  .slno-max-width {
    width: 5rem;
  }

  .sales-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .customer-row:hover {
    cursor: pointer;

    .branch-name {
      color: $primary !important;
    }
  }

  .no-data-description {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $dark-gray;
  }

  .card-header {
    background-color: $white;
    font-weight: 500;
    .card-description {
      font-weight: 500;
      font-size: $font-size-14px;
      color: $dark-gray;
    }
  }

  .customers-card {
    min-height: 30rem;
    min-width: 30rem;
    background-color: $white;
    border-radius: $font-size-10px;
    border: 1px solid $gray1;

    .name-badge {
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      font-size: $font-size-14px;
      min-width: 3.125rem !important;
      max-width: 3.125rem !important;
      max-height: 3.125rem !important;
      background-color: $yellow;
      word-break: keep-all;
    }

    .invitation-list {
      height: 100%;
      max-height: 26rem;
      overflow-y: auto;
    }

    .status-switch {
      background-color: $gray5;
      width: 12.625rem;
      height: 2rem;
      border-radius: 0.375rem;
      padding: 0.1rem;
      margin: auto;
    }

    .status-switch-toggle {
      color: $gray4;
      font-size: $font-size-14px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .status-switch-toggle-active {
      color: $black2;
      font-size: $font-size-14px;
      font-weight: 700;
      background: $white !important;
      border-radius: 0.375rem;
    }

    .border-top {
      border-top: 1px solid $gray1;
    }

    .date {
      font-size: $font-size-12px;
      color: $gray4;
    }

    .reject {
      color: $gray4;
      padding-right: 0.5rem;
      border-right: 1px solid $gray1;
    }

    .accept {
      padding-left: 0.5rem;
      color: $green;
    }
  }

  .no-sales-description {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $dark-gray;
    max-width: 20rem;
  }

  .no-sales {
    min-height: 30rem;
    justify-content: center;
    align-items: center;
    img {
      width: 15rem;
    }
  }
}
