@import "../../styles/style-variables.scss";

.payment-response {
  font-size: $font-size-12px;
  display: grid;
  vertical-align: middle;
  align-items: center;
  min-height: 75vh;
  padding: 2rem;

  .payment-response-wrapper {
    border-radius: 0.5rem;
    max-width: 35rem;
    background: white;
    padding: 3rem;
  }

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 1.875rem;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }

  .text-1 {
    color: $gray4;
    font-weight: 400;
  }

  .text-2 {
    color: $app-black;
    font-weight: 400;
  }

  .border {
    border: 1px solid #e9eaec;
    border-radius: 6px;
  }

  .border-top-1 {
    border-top: 1px solid #e9eaec;
    border-top-style: dashed;
  }

}

.payment-card {
  position: relative;
  background-color: $white;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .image-wrapper {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-image {
    width: 4rem;
  }

  .payment-title {
    font-size: $font-size-16px;
    font-weight: 600;
  }

  .payment-description {
    color: $gray4;
    font-weight: 500;
  }

  .continue-button {
    border: 1px solid #d5d8dc;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;

    .radio {
      width: 1.2rem;
    }

    .arrow-right {
      width: 0.5rem;
    }
  }
}

.payment-card {
  .status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .bank-account-number-container {
    display: flex;
    flex-direction: row;
  }

  .bank-account-number-text {
    color: $gray4;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .bank-account-number-text-star {
    margin-top: 3px;
  }
}