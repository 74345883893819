@import "../../../../styles/style-variables.scss";

.membership-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 60px;
  //card style
  box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
  border-radius: 10px;
  background: $white;
  border: 1px solid $gray1;
  margin-left: 4.5rem;
  min-height: 5rem;
  margin-top: 1.875rem;
  position: relative;
  padding-bottom: 35px;
  margin-top: 2.375rem;

  .platinum-container {
    display: flex;
    flex-direction: column;

    .platinum-hint {
      color: $gray3;
      font-style: italic;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      margin-top: 18px;
      max-width: 400px;
      text-align: center;

      a {
        color: $gray3;
        text-decoration: underline;
      }
    }

    .app-button-primary {
      margin-top: 1rem;
      margin-left: 10px;
      background-color: $black2;
      width: max-content;
      align-self: center;
    }
  }

  .membership-section {
    margin-left: 10px;

    .section-title {
      color: $black2;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      margin-top: 10px;
    }

    .membership-card.cursor-pointer:hover {
      box-shadow: 0px 0px 6px 0px rgba(93, 177, 255, 0.412);
      transform: scale(1.02);
    }

    .membership-card.cursor-pointer:active {
      opacity: 0.5;
    }

    .membership-card {
      box-shadow: 0px 1px 1px rgba(20, 53, 95, 0.05);
      border-radius: 0.5rem;
      background: $white;
      border: 1px solid $gray1;
      padding: 30px 70px;
      width: 405px;

      .profile-info {
        display: flex;
        flex-direction: row;

        .line1 {
          color: $black2;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 0;
        }

        .line2,
        .line3 {
          color: $gray4;
          font-weight: 500;
          font-size: 10px;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }

      .name-badge {
        word-break: keep-all;
        border-radius: 10px;
        text-align: center;
        font-weight: bold;
        font-size: $font-size-14px;
        width: 64px !important;
        height: 64px !important;
        background-color: $gray5 !important;
        color: $app-black;
        display: table-cell !important;
        vertical-align: middle;
        word-break: keep-all;
      }

      .membership-name {
        color: $black2;
        font-weight: 500;
        font-size: 21px;
        line-height: 14px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 12px;
      }

      .membership-type {
        color: $gray4;
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
      }
    }
  }
}

.membership-cancel-confirmation-modal {
  width: 500px !important;

  .description-text{
    white-space: pre-wrap;
    margin-top: 10px;
    color: $gray4;
  }
  .cancel-button {
    color: $green;
  }
  .ok-button {
    color: $red;
  }
}