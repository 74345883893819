@import "../../../styles/style-variables.scss";

.cart-footer {
  position: fixed;
  width: 100%;
  z-index: 1000;
  min-width: 65rem;
  bottom: 0;
  left: 0;
  padding: 0 2rem;
  border-top: 1px solid #e9eaec;
  animation: animation-cart-footer;
  animation-duration: 1s;

  .cart-item-wrapper {
    max-height: 12rem;
    overflow-y: auto;
  }

  .no-of-item {
    color: $red;
  }

  .cart-item {
    background-color: $gray5;
    border-radius: $font-size-10px;

    .flex-1 {
      flex: 0.2;
    }
    .flex-2 {
      flex: 0.7;
    }
    .flex-3 {
      flex: 0.1;
    }
    .qty {
      font-weight: 500;
      color: $gray4;
    }
  }

  .remove-qty {
    max-width: 1.5rem;
  }
}

@keyframes animation-cart-footer {
  0% {
    bottom: -7rem;
  }
  100% {
    bottom: 0;
  }
}
