@import "../../../styles/style-variables.scss";

.otp-input-wrapper {
  display: flex;
  justify-content: space-between;
  input {
    width: 3.5rem !important;
    height: 3.5rem !important;
    background-color: $gray6;
    border: none;
  }

  .input-with-value {
    background-color: white;
    border: 1px solid $primary-black;
  }
}

.min-width-100{
  min-width: 100%;
}
