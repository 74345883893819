@import "../../../styles/style-variables.scss";

.create-product {
}

.create-product-modal {
  margin-left: 0;
  margin-right: 0;
  max-width: 100% !important;
  margin-bottom: 0 !important;
  border-radius: none;
  overflow: hidden;

  .modal-body {
    padding: 0;
  }

  .modal-content {
    height: 90vh;
    margin-top: 6rem;
  }

  .create-product-header {
    height: 5.25rem;
    background: $sidebar-bg;

    .vetrical-center {
      align-items: center;
      height: 100%;
    }
  }

  .product-left-container {
    margin-right: 2.5rem;
  }

  .product-right-container {
    width: 100%;
  }

  .add-product-text {
    color: $white;
    font-size: $h2;
    font-weight: 600;
  }

  .remove-icon {
    height: 1.625rem;
    width: 1.625rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .added-image {
    height: 4rem;
    width: 4rem;
    border-radius: 5rem;
    // overflow: hidden;
    position: relative;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-icon {
    height: 2rem;
    width: 2rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .preview-container {
    height: 19.75rem;
    width: 22rem;
    overflow: hidden;
  }
  .preview-image {
    width: 100%;
    height: auto;
  }

  .create-product-content {
    display: flex;
    // min-height: 36rem;

    .image-upload {
      background: $gray5;
      height: 19.75rem;
      width: 22rem;
      border-radius: 0.625rem;
      border: 1px solid $gray1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .upload-image {
      border-radius: 5rem;
      width: 4.125rem;
      height: 4.125rem;
      background: $white;
      border: 1px solid $gray1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-image-size {
      height: 1.31rem;
      width: 1.31rem;
    }

    .sub-text {
      font-size: $caption;
      letter-spacing: 0.2em;
      color: $gray8;
      font-weight: 700;
    }

    .browse-text {
      color: $primary;
      font-size: $h5;
      font-weight: 700;
    }

    .drop-text {
      color: $black2;
      font-size: $h5;
      font-weight: 500;
    }

    .upload-image-bottom {
      color: $gray3;
      font-weight: 500;
      text-align: center;
      font-size: $bodyR;
      padding-top: 1rem;
    }

    .app-input-text-area {
      min-height: 10.56rem;
    }
  }

  .footer-product-create {
    // position: fixed;
    // bottom: 0;
    height: 6.5rem;
    width: 100%;
    border-top: 1px solid $gray1;
    background: $white;

    .cancel-button {
      width: 6.18rem;
      height: 3rem;
      border: 1px solid $gray2;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black2;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.375rem;
      cursor: pointer;
    }

    .continue-button-disabled {
      width: 7.375rem;
      height: 3rem;
      background: $gray3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.375rem;
      cursor: pointer;
    }

    .continue-button-enabled {
      width: 7.375rem;
      height: 3rem;
      background: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.375rem;
      cursor: pointer;
    }

    .button-container {
      float: right;
      justify-content: center;
      align-items: center;
      // margin-right: 2rem;
      margin-top: 1.5rem;
    }
  }

  .recently-added-title {
    color: $gray4;
    font-size: $font-size-12px;
    font-weight: 700;
    letter-spacing: 0.2em;
  }

  .product-list {
    background: rgba(242, 243, 245, 0.4);
    border: 1px solid rgba(213, 216, 220, 0.5);
    border-radius: 0.625rem;
    // height: 5.5rem;
  }

  .product-container {
    // max-height: 40rem;
    width: 80rem;
    margin: auto;
    height: 100%;
  }

  .preview-image {
    width: 22rem;
  }

  .price-display {
    min-width: 2rem;
    height: 2.5rem;
    background: $black2;
    color: $white;
    font-weight: 600;
    font-size: $bodyM;
    display: flex;
    border-radius: 0.375rem;
    justify-content: center;
    align-self: center;
    padding: 0.5rem 0.75rem 0.8rem 0.75rem;
  }

  .recently-added-container {
    margin-bottom: 3rem;
    margin-top: 2rem;
  }

  .product-name {
    color: $black2;
    font-weight: 700;
    font-size: $h3;
  }

  .product-code {
    font-size: $bodyM;
    color: $gray4;
    font-weight: 500;
  }

  .product-code-border {
    // border-right: 1px solid;
  }

  .product-quantity {
    color: $black2;
    font-size: $bodyM;
    font-weight: 500;
  }

  .description-display {
    font-size: $h5;
    font-weight: 500;
    color: $gray4;
  }

  .image-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-display-left {
    width: 20%;
    margin-left: 1rem;
  }

  .image-display-right {
    width: 80%;
  }

  .produt-list-alignment {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // justify-content: center;
    align-items: center;
  }

  .popup-close-icon {
    height: 2.25rem;
    width: 2.25rem;
  }

  .currency-position {
    position: absolute;
    bottom: 0.75rem;
    left: 2rem;
    font-size: $h5;
    color: $gray4;
  }

  .price-input-spacing {
    padding-left: 2rem;
  }

  .overflow-auto {
    overflow: auto;
  }

  .modal-container-product {
    width: 100%;
    height: 90vh;
    overflow: auto;
  }

  .modal-open .modal {
    overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
}

@media screen and (max-width: 1300px) {
  .create-product-header {
    width: 84rem !important;
  }

  .footer-product-create {
    width: 84rem !important;
  }
}
