@import "../home/styles/style-variables.scss";

.layout {
  background: $gray5;

  .main-component {
    min-height: 100vh;
    padding-bottom: 5rem;
    padding-top: 2rem;
  }

  .container-xl {
    max-width: 105rem !important;
  }
}
