@import "../../styles/style-variables.scss";

.dashboard {
  font-size: $font-size-14px;

  .flex-1 {
    flex: 0.6;
  }
  .flex-2 {
    flex: 0.4;
  }

  .flex-3 {
    flex: 0.5;
  }

  .status-switch {
    background-color: $white;
    width: 12.625rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    padding: 0.1rem;
    margin: auto;
  }

  .status-switch-toggle {
    color: $gray4;
    font-size: $font-size-12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status-switch-toggle-active {
    color: $white;
    background-color: $primary;
    font-size: $font-size-12px;
    font-weight: 700;
    border-radius: 0.375rem;
  }
}
