@import "../../HOME/styles/style-variables.scss";

.change-password {
  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 3.6rem;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }
}

.change-password-custom-modal {
  z-index: 99999;
}
