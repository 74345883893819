@import "../../../styles/style-variables.scss";

.accept-invitation {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #f1f1f1;

  .check-box-wrapper{
    position: absolute;
    padding-top: 2px;
  }
}

.shadow-lg-custom{
  box-shadow: 0 .3rem .7rem rgba(0,0,0,.175)!important;
}