@import "../../styles/style-variables.scss";

.staff-login {
  font-size: $font-size-12px;
  min-height: 87vh;
  display: flex;
  align-items: center;
  padding: 2rem;
  background-color: $gray5 !important;

  .sub-title {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #e9eaec;
  }

  .login-card {
    max-width: 30rem !important;
    min-width: 25rem !important;
    position: relative;
    border: 1px solid #d5d8dc;
    border-radius: $font-size-10px;
  }

  .border-card {
    border: 1px solid #d5d8dc;
    border-radius: $font-size-10px;
  }

  .header-text {
    font-size: $font-size-18px;
    font-weight: 700;
  }

  .click-here {
    color: #21b8c7;
    cursor: pointer;
  }

  .google-login {
    border: 1px solid #d5d8dc;
    box-sizing: border-box;
    border-radius: $font-size-10px;
    button {
      display: inline-flex;
      align-items: center;
      width: 100% !important;
      padding: $font-size-10px;
      justify-content: center;
      box-shadow: none !important;
      background-color: transparent !important;
      div {
        margin: 0 !important;
      }
      span {
        color: $app-black !important;
        font-size: $font-size-12px;
      }
    }
    button:focus {
      outline: none !important;
    }
  }

  .fb-login {
    display: inline-flex;
    align-items: center;
    width: 100% !important;
    padding: $font-size-10px;
    justify-content: center;
    border: 1px solid #d5d8dc;
    box-sizing: border-box;
    border-radius: $font-size-10px;
    cursor: pointer;
    img {
      width: 1.25rem;
    }
  }

  .or {
    max-width: 6.25rem !important;
    // margin-top: 8.125rem !important;
    font-weight: 600;
    color: $dark-gray;
    align-self: center;
  }

  .line::after {
    width: $font-size-10px;
    height: 1px;
    background: $gray1;
    content: "";
    position: absolute;
    left: 70%;
    top: 0.6rem;
    overflow: hidden;
  }

  .line::before {
    width: $font-size-10px;
    height: 1px;
    background: $gray1;
    content: "";
    position: absolute;
    left: 20%;
    top: 0.6rem;
    overflow: hidden;
  }
  .user-image {
    padding: 1.25rem;
    border-radius: 50%;
    background-color: $gray5;
    position: absolute;
    margin-top: -30px;
    margin-left: -30px;
  }

  .forgot {
    font-weight: 500;
  }

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 1.875rem;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }
}
