@import "../../../styles/style-variables.scss";

.membership-and-billing {
   .arrow-left {
      height: 1.5rem;
      width: 1.5rem;
   }

   .bill-summary-container {
      margin-left: 4.5rem;
      margin-top: 2.375rem;
   }
}