@import "../../../styles/style-variables.scss";

.customer-invoice {
  font-size: $font-size-14px;

  .invoice-number {
    font-weight: 700;
    font-size: $font-size-14px;
  }

  .semi-bold-text {
    font-weight: 600;
  }

  .bold-text {
    font-weight: 800;
  }

  .load-more {
    bottom: 0rem;
    color: $black2;
    font-weight: 600;
    font-size: $caption;
    padding-bottom: 2rem;
  }

  .main-filter-badge {
    right: 0.5rem;
    top: 0;
  }

  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    // margin-left: 10.5rem;
    right: 1rem;
    margin-top: -0.5rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .filterpop-over {
    margin-left: -15rem !important;
  }

  td {
    vertical-align: middle !important;
  }

  tr {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    border-left: 1px solid $gray1;
    vertical-align: middle;
  }

  .tr-complete {
    background-color: $gray5;
    color: $gray3 !important;
    .invoice-number {
      color: $gray3;
    }
  }

  th {
    font-weight: 500;
    font-size: $font-size-14px;
    color: $gray3;
    vertical-align: middle !important;
    height: 3rem;
  }

  .created-date {
    color: $gray3;
  }

  .table-responsive {
    min-height: 25rem !important;
  }

  .filter-wrapper {
    position: relative;
    display: flex !important;
    justify-content: center;
    border: 1px solid $gray2;
    box-sizing: border-box;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    max-width: 8rem;
    float: left;
    .filter-text {
      font-weight: 700;
      font-size: $font-size-16px;
    }
  }

  .invoice-status-badge {
    border-radius: 0.4rem;
    padding: 0.4rem 1rem;
  }

  .invoice-new {
    background-color: $green;
    color: white;
    .draw-dot {
      background-color: white;
    }
  }

  .invoice-due {
    background-color: $red1;
    color: $red;
    .draw-dot {
      background-color: $red;
    }
  }

  .invoice-pending {
    background-color: $yellow1;
    color: $yellow;
    .draw-dot {
      background-color: $yellow;
    }
  }

  .invoice-partial {
    background-color: $blue1;
    color: $primary;
    .draw-dot {
      background-color: $primary;
    }
  }

  .invoice-complete {
    background-color: $gray1;
    color: $gray4;
    .draw-dot {
      background-color: $gray4;
    }
  }

  .draw-dot {
    content: ".";
    border-radius: 50%;
    height: 0.4rem;
    width: 0.4rem;
    position: absolute;
    top: 0.8rem;
    left: 0.5rem;
  }

  .filter-header {
    padding: 1.25rem 1.25rem 1.125rem 1.25rem;
    color: $gray3;
    font-size: $caption;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $gray1;
  }

  .filter-search-wrapper {
    height: 2rem;
    background: $gray5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
  }

  .filter-search-icon {
    height: 0.6rem;
    width: 0.6rem;
  }

  .filter-search {
    height: 100%;
    width: 90%;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background: $gray5;
    color: $gray4;
    font-size: $font-size-11px;
    border: none;
  }

  .filter-search:focus {
    outline: none;
  }

  .down-arrow {
    height: 0.5rem;
    width: 0.7rem;
    margin-left: 1rem;
    cursor: pointer;
  }

  .filter-left {
    padding-left: 1rem;
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-center {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-right {
    border-bottom: 1px solid $gray1;
    padding-top: 1rem;
  }

  .product-expand-view {
    left: 8rem !important;
  }
  .export-icon {
    height: 2.5rem;
    margin-right: 1.5rem;
    cursor: pointer;
  }

  .export-icon:active {
    opacity: 0.7;
  }


  .tab {
    font-size: $font-size-16px;
    display: inline-block;
    cursor: pointer;
    padding-bottom: 0.5rem;
  }

  .active-tab {
    font-size: $font-size-18px;
    font-weight: 600;
    border-bottom: 0.25rem solid $primary;
  }
}
