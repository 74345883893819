@import "../../styles/style-variables.scss";

.signup {
  font-size: $font-size-12px;
  min-height: 87vh;
  display: grid;
  overflow: hidden;
  // padding: 2rem;

  .signup-container {
    display: flex;
  }

  .left-container {
    flex: 40%;
    height: 100%;
    overflow: hidden;

    .signup-image {
      position: fixed;
      width: 40%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .right-container {
    flex: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .singup-image {
    width: 100%;
    max-width: 31.25rem;
  }

  .login-card {
    width: 25rem !important;
    position: relative;
    border: 1px solid #d5d8dc;
    border-radius: 10px;
  }

  .singup-card {
    max-width: 31.25rem !important;
    min-width: 26.875rem;
  }

  .click-here {
    color: $primary;
    cursor: pointer;
  }

  .text-1 {
    color: $dark-gray;
    font-weight: 500;
  }

  .lock-image {
    padding: 1.25rem;
    border-radius: 50%;
    background-color: $gray5;
  }

  .tc {
    font-weight: 700;
    padding-bottom: 5px;
    // border-bottom: 1px solid $primary;
  }

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 58px;
    right: $font-size-10px;
    padding: 0 0.25rem;
    background: $white;
  }

  .sub-header-text {
    font-size: $font-size-16px;
    font-weight: 300;
  }
}
