@import "../../../styles/style-variables.scss";

.calendar {
  // background: #F2F3F5;
  // min-height: 100vh;
  // padding: 10rem;

  .days {
    background: $white;
    border: 1px solid $gray1;
    padding: 1rem;
    border-radius: 0.25rem;
  }
  .days-weekly {
    background: $white;
    border: 1px solid $gray1;
    border-radius: 0.25rem;
    position: relative;
  }

  .menu-button {
    display: inline-block;
    padding: 0.25rem 1rem;
    cursor: pointer;
    font-size: $font-size-14px;
  }

  .days-text-weekly {
    padding: 1rem;
  }

  .days-text-weekly-border {
    border-right: 1px solid $gray2;
  }

  .days-text {
    color: $black2;
    font-weight: 600;
    font-size: $bodyR;
  }

  .days-text-border {
    border-right: 1px solid $gray2;
  }

  .disabled-cell-number {
    font-weight: 700;
    font-size: $h5;
    color: $gray3 !important;
  }

  .calendar-cell {
    width: 11rem;
    height: 10.18rem;
    padding: 1rem;
    overflow: hidden;
    z-index: 998;
  }

  .calendar-cell-border-right {
    border-right: 1px solid $gray2;
  }

  .calendar-cell-border-bottom {
    border-bottom: 1px solid $gray2;
  }

  .current-date-month {
    display: inline-block;
    font-size: $h5;
    color: $gray4;
  }

  .down-arrow {
    height: 0.5rem;
    width: 0.7rem;
    margin-left: 1rem;
    cursor: pointer;
  }

  .calendar-number {
    color: $black2;
    font-size: $bodyR;
    font-weight: 700;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar-row-wrapper {
    box-shadow: 0px 44px 54px rgba(30, 46, 66, 0.06);
    border: 1px solid $gray2;
    background: $white;
    margin-top: 0.9rem;
    border-radius: 0.375rem;
  }

  .arrow-icon-image {
    width: 0.25rem;
    height: 0.5rem;
  }

  .disabled-bar-wrapper {
    transform: matrix(0.99, -0.7, 0.09, 0.87, 0, 0);
    bottom: -50rem;
    position: absolute;
    top: -10rem;
    z-index: -999;
  }

  .disabled-bar {
    height: 0.96rem;
    background: $gray1;
    width: 20rem;
    margin-bottom: 1rem;
  }

  .right-arrow-icon-weekly {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5rem;
    background: $white;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    right: -0.8rem;
    cursor: pointer;
    margin-top: 1.5rem;
    z-index: 998;
  }

  .left-arrow-icon-weekly {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5rem;
    background: $white;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    left: -0.8rem;
    margin-top: 1.5rem;
    cursor: pointer;
    z-index: 998;
  }

  .left-arrow-icon {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5rem;
    background: $white;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .down-arrow-calendar {
    height: 0.5rem;
    width: 0.7rem;
  }

  .calendar-title {
    font-size: $h2;
    font-weight: 600;
    color: $black2;
    margin-bottom: 1rem;
  }

  .calendar-switch {
    background-color: $white;
    width: 11.31rem;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
    border-radius: 0.375rem;
    float: right;
    height: 3rem;
    padding: 0.1rem;
  }

  .calendar-switch-toggle {
    color: $black2;
    font-size: $h5;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .col-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .col-center-weekly {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .calendar-switch-toggle-active {
    color: $black2;
    font-size: $h5;
    font-weight: 700;
    background: $gray5;
  }

  .weekly-date-text {
    color: $gray4;
    font-size: $h3;
    font-weight: 700;
  }

  .weekly-date-text-small {
    color: $gray3;
    font-size: $bodyR;
    font-weight: 500;
  }

  .calendar-margin {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .order-left-image {
    height: 2.265rem;
    width: 2.265rem;
  }

  .order-number {
    color: $black2;
    font-weight: 700;
    padding-left: 1rem;
    font-size: $h5;
  }

  .business-name {
    color: $black2;
    font-weight: 500;
    font-size: $h5;
  }

  .orders-branch {
    padding-top: 1rem;
  }

  .ordered-date {
    color: $gray4;
    font-weight: 500;
    font-size: $bodyR;
  }

  .delivery-date {
    color: $black2;
    font-weight: 500;
    font-size: $bodyR;
  }

  .orders {
    min-height: 37rem;
    box-shadow: 0px 44px 54px rgba(30, 46, 66, 0.06);
    background: $white;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border: 1px solid $gray1;
    border-top: 0 !important;
  }

  .orders-row {
    background: $gray5;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
  }

  .currency {
    color: $black2;
    font-size: $bodyR;
    font-weight: 700;
  }

  .order-new {
    background-color: $green;
    color: white;
    .draw-dot {
      background-color: white;
    }
  }

  .order-due {
    background-color: $red1;
    color: $red;
    .draw-dot {
      background-color: $red;
    }
  }

  .order-pending {
    background-color: $yellow1;
    color: $yellow;
    .draw-dot {
      background-color: $yellow;
    }
  }

  .order-partial {
    background-color: $blue1;
    color: $primary;
    .draw-dot {
      background-color: $primary;
    }
  }

  .order-complete {
    background-color: $gray1;
    color: $gray4;
    .draw-dot {
      background-color: $gray4;
    }
  }

  .extra-order {
    width: 1.875rem;
    height: 1.5rem;
    background: $black2;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 0.5rem;
    color: $white;
    font-weight: 700;
    font-size: $bodyR;
    cursor: pointer;
  }

  .load-more {
    bottom: 0rem;
    color: $black2;
    font-weight: 600;
    font-size: $caption;
    padding-bottom: 2rem;
  }

  .order-badge {
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .selected-date {
    background: $primary !important;
    color: $white;
  }

  .selected-text {
    color: $white;
  }
  .product-expand-view {
    left: 8rem;
  }
}

.loading-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
