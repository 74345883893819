@import "../../styles/style-variables.scss";

.email-container {
    margin: 1rem;
    font-size: $font-size-14px ;
    .arrow-left {
        height: 1.5rem;
        width: 1.5rem;
     }
  

    .custom-control {
        display: inline-block !important;
    }

    .action-flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .flex-2{
            flex: .2;
        }
    }

    .email-container-inside {
        border: 1px solid #e7e7e7;
        background-color: white;
        margin: 2.5rem;
        border-radius: 10px;

        .email-notification-header {
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 3rem;
                margin: 1rem 0 1rem 1rem;
            }

            .header-title {
                display: inline-block;
                flex: 0.8;
                padding-left: 0.5rem;
            }
        }
    }
}