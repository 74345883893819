@import "../../../home/styles/style-variables.scss";

.my-network {
   position: relative;
   font-size: $font-size-14px;
   color: $primary-black;

   .user-group-icon {
      width: 2rem;
   }

   .my-network-menu {
      padding: 1rem;

      .my-network-title {
         color: $primary-black;
         font-weight: 600;
      }

      .my-network-text {
         font-weight: 500;
         margin-left: 5px;
      }

      .arrow-icon {
         height: 12px !important;
         margin-left: 5px;
      }
   }

}

.mynetwork-custom-modal {
   position: fixed;
   z-index: 1001;

   .pop-over-mynetwork {
      .menu-card {
         font-size: $font-size-14px;
         min-height: 8rem;
         max-height: 25rem;
         min-width: 15rem;
         background: $white;
         border-radius: 0.375rem;
         border: 2px solid $gray5;
         position: absolute;
         margin-top: 5px;
         margin-left: -5rem;
      }

      .arrow-up {
         position: absolute;
         display: block;
         width: 1rem;
         height: 0.5rem;
         margin: 0 2rem;
         margin-top: -4px;
      }

      .arrow-up::before {
         position: absolute;
         display: block;
         content: "";
         border-color: transparent;
         border-style: solid;
         border-bottom-color: white;
         border-width: 0 0.5rem 0.5rem 0.5rem;
      }

      .arrow-up::after {
         position: absolute;
         display: block;
         content: "";
         border-color: transparent;
         border-style: solid;
      }

      .arrow-up-wrapper {
         width: 0;
         height: 0;
         border: solid $gray5;
         border-width: 0 0.1rem 0.1rem 0;
         display: inline-block;
         padding: 0.3rem;
         margin: auto;
         margin-top: -8px;

         transform: rotate(-135deg);
         -webkit-transform: rotate(-135deg);
      }
   }
}


.add-custom-modal {
   // font-size: $font-size-12px;
   // display: grid;
   align-items: center;
   // height: 85vh;
   min-width: 700px !important;
   height: 500px;

   .modal-content {
      margin-top: calc((100% - 500px) / 2);
   }
}

.add-modal-content {
   max-height: 500px;
   overflow-y: auto;

   .app-search-gray {
      border-radius: 1.25rem;
      background: $gray5;
   }

   .app-search-input-gray {
      border-radius: 0.625rem;
      width: 100%;
      min-width: 25rem;
      height: 3rem;
      border: none;
      font-size: $font-size-14px;
      background-color: $gray5;
      display: flex;
      flex-direction: row;
   }

   .search-input-box-gray {
      width: 85%;
      border: none;
      background-color: $gray5;
      border-radius: 0.625rem;
   }

   .search-input-box-gray:focus {
      outline: none;
   }

   .search-icon {
      height: 1.5rem;
      width: 1.5rem;
   }

   .app-input-override {
      width: 50%;
   }

   .app-button-primary-override {
      padding: 0.8rem 1rem;

   }
}