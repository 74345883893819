@import "../../../styles/style-variables.scss";

.product-preview-modal {
  .product-preview {
    word-break: break-word;

    margin: 1.5rem;

    .product-images {
      width: 28rem;
      height: 14.18rem;
      border-radius: 0.375rem;
      object-fit: contain;
    }

    .image-swipe-left {
      height: 2.06rem;
      width: 2.06rem;
      position: absolute;
      align-self: center;
      top: 7rem;
      left: 0.4rem;
      cursor: pointer;
    }

    .image-swipe-right {
      height: 2.06rem;
      width: 2.06rem;
      position: absolute;
      align-self: center;
      right: 0.4rem;
      top: 7rem;
      cursor: pointer;
    }

    .product-name-preview {
      font-weight: 700;
      font-size: $h3;
      color: $black2;
    }

    .vendor-name-preview {
      color: $gray4;
      font-size: $h5;
      font-weight: 400;
    }

    .favorate-image {
      width: 2.375rem;
      height: 2.375rem;
    }

    .border-bottom {
      border-bottom: 1px solid $gray1;
      align-items: center;
    }

    .description-title {
      font-size: $font-size-12px;
      color: $black2;
      letter-spacing: 0.2em;
      font-weight: 700;
    }

    .description {
      color: $gray4;
      font-size: $bodyR;
    }

    .gray-dot {
      background-color: $gray3;
      height: 0.3rem;
      width: 0.3rem;
      border-radius: 12rem;
      display: inline-block;
      margin-bottom: 0.25rem;
    }

    .app-button-primary {
      padding: 0.8rem 1rem;
    }

    .price-unit {
      font-size: $h5;
      color: $black2;
      font-weight: 700;
    }

    .app-input {
      padding-right: 3.5rem;
    }

    .unit-text {
      font-weight: 500;
      font-size: $bodyR;
      color: $gray4;
      position: absolute;
      right: 1.8rem;
      padding-top: 0.85rem;
    }

    .input-container {
      display: flex;
      flex-direction: row;

      .input-left {
        width: 80%;
        display: inline-block;
        position: relative;
      }

      .input-right {
        width: 20%;
        display: inline-block;
        padding-left: 1.5rem;
      }
    }
  }
}
