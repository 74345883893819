@import "../../../styles/style-variables.scss";

.app-header {
  height: 4rem;
  position: fixed;
  min-width: 64rem;
  z-index: 10000;
  top: 0;
  padding: 0 2rem;
  border-bottom: 1px solid #e9eaec;

  .logo {
    padding: 0.8rem 0 0 0rem;
    height: 3.2rem;
  }

  .logo-image {
    overflow: hidden;
    max-height: 3rem;
    margin-right: 0.5rem;

    img {
      //width: 100%;
      max-height: 3rem;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .text-1 {
    font-size: 0.7rem;
    letter-spacing: 0.01;
    color: $app-black;
    cursor: pointer;
    padding-right: 0.2rem;
    color: #78828e;
    font-weight: 700;
  }

  .text {
    font-size: 0.7rem;
    letter-spacing: 0.01;
    color: $app-black;
    cursor: pointer;
    font-weight: 700;
  }

  .text-icon {
    height: 2rem;
    cursor: pointer;
  }

  .bg-white {
    background-color: $white;
  }

  .profile-menu {
    background-color: $white;
    position: absolute;
    font-size: 0.7rem;
    box-shadow: 0px 10px 40px -18px rgba(0, 0, 0, 0.33);
  }

  .count-badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #21b8c7;
    font-weight: 600;
    color: white;
  }

  .user-circle {
    height: 48px;
    width: 48px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #f2f3f5;
  }

  .user-image {
    height: 24px;
    width: 24px;
  }

  .user-container {
    flex-direction: row;
  }

  .down-arrow-header {
    margin-left: 10px;
    width: 0.6rem;
  }

  .user-name {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    font-weight: 700;
    font-size: $h5;
    color: $black2;
  }

  .pop-over-logout {
    min-height: 100px;
    min-width: 150px;
    background: $white;
    border-radius: 10px;
    top: 60px;
    right: 0px;
    border: 1px solid $gray1;
    position: absolute;
  }
}
