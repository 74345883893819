//New Module for Inventory INV000001
@import "../../../styles/style-variables.scss";

.custom-modal-import-export-product {
   margin-top: 8rem !important;
}

.import-export-modal-body {
   .header-text {
      text-align: center;
      font-size: 24px;
   }

   .error-message{
      color: $red;
   }

   .content {
      margin: 35px 95px;
      min-height: 200px;
   }

   .action-dropdown:active {
      opacity: 0.7;
   }

   .sub-text {
      font-style: italic;
      // text-align: justify;
      font-size: $font-size-12px;
      color: $gray4;
    }

   .check-format {
      font-size: $font-size-10px;
      font-style: italic;
      font-weight: 600;
      color: $primary;
    }
  

   .import-excel-div {
      background: #f6f7f5;
      border-radius: 5px;
      min-height: 35px !important;
    }

  .excel {
   cursor: pointer;
   font-weight: 800;
   font-size: $font-size-14px;
   line-height: 19px;
   text-align: center;
 }

   .action-dropdown-container {
      position: relative;

      .action-dropdown {
         text-align: center;
         background-color: #F2F3F5;
         border-radius: 5px;
         padding: 10px;
         cursor: pointer;

         .blue-down-arrow {
            position: absolute;
            top: 17px;
            right: 26px;
            height: 10px;
         }
      }


      .action-dropdown-items {
         text-align: center;
         background-color: #F2F3F5;
         border-radius: 5px;
         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

         position: absolute;
         width: 100%;
         z-index: 1;

         .action-dropdown-item:active {
            opacity: 0.7;
         }

         .action-dropdown-item:hover {
            background-color: #e6e6e6;
         }

         .action-dropdown-item {
            padding: 6px;
            font-size: 14px;
            cursor: pointer;
         }

         .action-dropdown-item.active {
            background: #D9D9D9;
         }
      }
   }

   .select-criteria-container {
      .filters {
         .checkbox {
            height: 0.875rem;
            width: 0.865rem;
         }

         .filter-popover {
            background: $white;
            border: 1px solid rgba(24, 24, 51, 0.07);
            border-radius: 0.375rem;
            width: 35rem;
            text-align: left;
            margin-top: 1rem;
            padding-bottom: 1rem;

            .category-list {
               max-height: 10.5rem;
               min-height: 10.5rem;
               overflow-y: auto;
            }

            .categories-name {
              color: $black2;
              font-weight: 500;
              font-size: $bodyR;
            }
          
            .categories-name-active {
              color: $black2;
              font-weight: 600;
              font-size: $bodyR;
            }

            .title-pop-over {
               font-size: $h5;
               color: $black2;
               font-weight: 700;
            }
         }

         .filter-header {
            padding: 1.25rem 1.25rem 1.125rem 1.25rem;
            color: $gray3;
            font-size: $caption;
            font-weight: 700;
            text-align: left;
            border-bottom: 1px solid $gray1;
            display: flex;
         }

         .filter-search-wrapper {
            height: 2rem;
            background: $gray5;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.375rem;
         }

         .filter-search-icon {
            height: 0.6rem;
            width: 0.6rem;
         }

         .filter-search {
            height: 100%;
            width: 90%;
            padding: 0.5rem;
            border-radius: 0.375rem;
            background: $gray5;
            color: $gray4;
            font-size: $font-size-11px;
            border: none;
         }

         .filter-search:focus {
            outline: none;
         }

         .filter-left {
            padding-left: 1rem;
            // border-bottom: 1px solid $gray1;
            border-right: 1px solid $gray1;
            padding-top: 1rem;
         }

         .filter-center {
            // border-bottom: 1px solid $gray1;
            border-right: 1px solid $gray1;
            padding-top: 1rem;
         }

         .filter-right {
            // border-bottom: 1px solid $gray1;
            padding-top: 1rem;
         }
      }
   }
}