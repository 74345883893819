@import "../../styles/style-variables.scss";

.products-v2 {
  table td {
    vertical-align: middle !important;
  }

  .tab-wrapper {
  }


  .arrow-up {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    margin-left: 18rem;
    // right: 1rem;
    margin-top: -0.5rem;
  }

  .arrow-up::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-bottom-color: white;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }

  .arrow-up::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .slno-max-width {
    width: 5rem;
  }

  .category-max-width {
    width: 10rem;
  }

  .description-max-width {
    width: 20rem;
  }

  .quantity-max-width {
    width: 10rem;
  }

  .price-max-width {
    width: 10rem;
  }

  .price-setup-width {
    width: 12rem;
  }

  .name-center {
    align-items: center;
  }

  .product-code {
    color: $gray4;
    font-size: $bodyR;
    font-weight: 500;
  }

  .down-arrow {
    height: 0.5rem;
    width: 0.7rem;
    margin-left: 1rem;
    cursor: pointer;
  }

  .product-header-title {
    font-size: $h2;
    font-weight: 600;
    color: $black2;
  }

  .header-devider {
    background: $gray2;
    height: 1.25rem;
    width: 0.0625rem;
  }

  .product-list-search {
    height: 1.5rem;
    width: 1.5rem;
  }

  .product-list-filters {
    height: 1.5rem;
    width: 1.5rem;
  }

  .card-view-logo {
    height: 1rem;
    width: 1rem;
  }

  .list-view-logo {
    height: 1rem;
    width: 1rem;
  }

  .toggle-switch-active {
    background: $gray5;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }

  .toggle-switch {
    width: 6.75rem;
    height: 3rem;
    box-shadow: 0px 0.1875rem 0.125rem rgba(20, 53, 95, 0.05);
    border-radius: 0.625rem;
    padding: 0.3rem;
    background: $white;
    // justify-content: center;
    // align-items: center;
    flex-direction: row;
  }

  .toggle-left {
    width: 50%;
    height: 100%;
    display: inline-block;
  }

  .toggle-right {
    width: 50%;
    height: 100%;
    display: inline-block;
  }

  .toggle-right-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .toggle-left-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .card-spacing {
    // justify-content: space-between;
    margin-right: 1rem;
  }

  .spacing {
    // justify-content: space-between;
  }

  .add-product-button {
    display: inline-block;
  }

  .header-right-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .product-name-list {
    font-weight: 700;
    font-size: $bodyR;
    color: $black2;
  }

  .product-image-list {
    height: 3rem;
    width: 3rem;
    border-radius: 0.125rem;
  }

  .price-setup-text {
    color: $gray4;
    font-size: $bodyR;
    font-weight: 500;
  }

  .edit-icon {
    width: 1rem;
    height: 1rem;
  }

  .products-row:hover {
    background-color: $gray1;
    cursor: pointer;
  }

  .product-list-table {
    background-color: $white;
    border-radius: 0.375rem;
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);

    tr th {
      color: $gray3;
      font-weight: 500;
      font-size: $bodyR;
      border-bottom: none;
      border: none;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .product-list-table-wrapper {
    box-shadow: 0px 3px 2px rgba(20, 53, 95, 0.05);
  }

  .price-setup-button {
    width: 12.25rem;
    height: 2.5rem;
    border: 0.0625rem solid $gray2;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .price-setup-button:hover {
    border: 0.0625rem solid $primary;
  }

  .semi-bold-text {
    color: $black2;
    font-weight: 500;
    font-size: $bodyR;
  }

  .product-image {
    height: 3rem;
    width: 3rem;
    border-radius: 0.125rem;
  }

  .light-text {
    font-weight: 400;
    color: $gray4;
    font-size: $bodyR;
  }

  .search-input-input {
    border: none;
    height: 100%;
    width: 90%;
    padding: 1rem;
    border-radius: 0.625rem;
    font-size: $bodyR;
  }

  .search-input-input:focus {
    outline: none;
  }

  .search-input {
    background: $white;
    border: 1px solid $gray1;
    height: 3rem;
    width: 25rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
  }

  .filterpop-over {
    background: $white;
    border: 1px solid rgba(24, 24, 51, 0.07);
    position: absolute;
    border-radius: 0.375rem;
    width: 35rem;
    height: 23.18rem;
    top: 4rem;
    text-align: left;
    margin-left: -18rem;
    z-index: 997;

    .category-list {
      max-height: 10.5rem;
      min-height: 10.5rem;
      overflow-y: auto;
    }

    .title-pop-over {
      font-size: $h5;
      color: $black2;
      font-weight: 700;
    }
  }

  .filter-header {
    padding: 1.25rem 1.25rem 1.125rem 1.25rem;
    color: $gray3;
    font-size: $caption;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $gray1;
  }

  .filter-search-wrapper {
    height: 2rem;
    background: $gray5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
  }

  .filter-search-icon {
    height: 0.6rem;
    width: 0.6rem;
  }

  .filter-search {
    height: 100%;
    width: 90%;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background: $gray5;
    color: $gray4;
    font-size: $font-size-11px;
    border: none;
  }

  .filter-search:focus {
    outline: none;
  }

  .filter-left {
    padding-left: 1rem;
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-center {
    border-bottom: 1px solid $gray1;
    border-right: 1px solid $gray1;
    padding-top: 1rem;
  }

  .filter-right {
    border-bottom: 1px solid $gray1;
    padding-top: 1rem;
  }

  .categories-name {
    color: $black2;
    font-weight: 500;
    font-size: $bodyR;
  }

  .categories-name-active {
    color: $black2;
    font-weight: 600;
    font-size: $bodyR;
  }

  .reset-button {
    width: 4.875rem;
    display: flex;
    height: 2.375rem;
    border: 1px solid $gray2;
    color: $black2;
    font-size: $h5;
    font-weight: 700;
    border-radius: 0.375rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
  }

  .apply-button {
    width: 8.18rem;
    display: flex;
    height: 2.375rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid $primary;
    font-size: $h5;
    font-weight: 700;
    color: $primary;
    cursor: pointer;
  }

  .checkbox {
    height: 0.875rem;
    width: 0.865rem;
  }

  .button-wrapper {
    float: right;
    margin: 0;
    margin-right: 1rem;
  }

  .description-max-height {
    max-height: 3.5rem;
  }
}

.product-bulk-import {
  padding: 1rem;

  .sub-text {
    font-style: italic;
    // text-align: justify;
    font-size: $h5;
    color: $gray4;
  }

  .file-name {
    max-width: 20rem;
    overflow-x: auto;
  }

  .check-format {
    font-size: $font-size-10px;
    font-style: italic;
    font-weight: 600;
    color: $primary;
  }

  .optional-text {
    font-weight: 500;
    font-size: $font-size-10px;
    margin-bottom: 0 !important;
    color: #bcb8b5;
  }

  .cw-table {
    border-collapse: collapse !important;
  }

  .border-bottom {
    padding-bottom: 30px;
    border-bottom: 1px solid #dbd9d9 !important;
  }

  .class-tab {
    font-weight: 700;
  }

  .active-class {
    border-bottom: 4px solid gray;
  }

  .max-30 {
    max-width: 30px !important;
  }

  .tr-border {
    border-right: 1px solid #21b8c7 !important;
    border-left: 1px solid #21b8c7 !important;
  }

  .no-padding {
    padding-bottom: 0 !important;
  }

  .import-excel-div {
    background: #f6f7f5;
    border-radius: 30px;
    min-height: 35px !important;
  }

  .excel {
    cursor: pointer;
    font-weight: 800;
    font-size: $font-size-14px;
    line-height: 19px;
    text-align: center;
  }

  .duplicate {
    background-color: #fef2f2;

    img {
      width: 15px;
    }
  }

  .copy {
    background-color: #fff5f5 !important;
    border-bottom: 2px solid #dbd9d9 !important;
  }

  .border-right {
    border-right: 1px solid #dbd9d9 !important;
  }

  .check-text {
    font-size: $font-size-12px;
    color: #008fff;
    cursor: pointer;
  }
}

.no-product-image {
  width: 15rem;
  height: 10rem;
}

.no-product-text {
  font-size: $font-size-20px;
  color: $black2;
  font-weight: 700;
  text-align: center;
}

.product-expand-view {
  width: 18rem;
  min-height: 8.56rem;
  background: $white;
  border: 1px solid rgba(24, 24, 51, 0.07);
  border-radius: 0.625rem;
  position: absolute;
  left: 12rem;
  bottom: -4.5rem;
  z-index: 997;
  padding-top: 1rem;

  .name-wrapper {
    font-size: $bodyR;
    color: $gray4;
    font-weight: 500;
    margin: 0rem 1.5rem 0rem 1.5rem;
    border-radius: 0.375rem;
  }

  .name {
    font-size: $bodyR;
    color: $gray4;
    font-weight: 500;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    border-radius: 0.375rem;
    position: relative;
    cursor: pointer;
    word-break: keep-all;
  }

  .name-active {
    font-size: $bodyR;
    color: $black2;
    font-weight: 700;
  }

  .name:hover {
    background: $gray5;
  }

  .green-tick-new {
    width: 0.625rem;
    height: 0.43rem;
    position: absolute;
    left: 0;
    top: 1rem;
  }
}
